import React from "react";
import Bannerlogo from "../../assets/imgs/bannerlogo.png";

const HomeBanner = () => {
  return (
    <div className="banner_bg">
      <div className="banner_logo_img">
        <img src={Bannerlogo} alt="" />
      </div>
    </div>
  );
};

export default HomeBanner;
