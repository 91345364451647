import React from "react";
import elderbird from "../../assets/imgs/elderbird.png";
import PlayCard from "../../assets/imgs/HomepagePlayCard.png";
import CollectCard from "../../assets/imgs/HomepageCollectCard.png";
import BuildCard from "../../assets/imgs/HomepageBuildCard.png";
import EarnCard from "../../assets/imgs/HomepageEarnCard.png";
import Checkout from "./Checkout";

const Greeting = () => {
  return (
    <>
      <div className="Section bg_yellow">
        <div className="container-fluid px-3">
          <h5 className="promo__title promo__title_decorative">
            Welcome To Tanjea
          </h5>
          <p>
            A mobile strategy game hosted on the Ethereum blockchain. Enter
            daily competitions and battles <br></br> hosted on land parcels
            across each kingdom to earn TNJ tokens. Win the most TNJ with your
            clan<br></br> to become one of the rulers of Tanjea's 8 kingdoms.
          </p>
          <img src={elderbird} className="elderbird_img mobilehidden" alt="" />
          <div className="row">
            <div className="col-lg-3 col-sm-6 my-3">
              <div className="Greeting_card">
                <img src={PlayCard} alt="" />
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 my-3">
              <div className="Greeting_card">
                <img src={CollectCard} alt="" />
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 my-3">
              <div className="Greeting_card">
                <img src={BuildCard} alt="" />
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 my-3">
              <div className="Greeting_card">
                <img src={EarnCard} alt="" />
              </div>
            </div>
          </div>
        </div>
       <div className="mt-5">
       <Checkout/>
       </div>
      </div>
    </>
  );
};

export default Greeting;
