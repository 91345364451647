import { Row, Col, Button } from "reactstrap";
import ConfirmEmail from "../../assets/images/confirm-email.png";
const EmailConfirm = () => {
  return (
    <section className="set-password">
      <div className="container">
        <Row className="justify-content-center align-items-center h-100-vh">
          <Col md="12" lg="12">
            <div>
              <h4 className="d-flex justify-content-center align-items-center">
                <img
                  width={50}
                  src={ConfirmEmail}
                  alt="Confirm Email"
                  className="loginScreenLogo mb-0 mr-3"
                />
                Congratulations, you have successfully confirmed your email. You
                can log in into the game now.
              </h4>
            </div>
            <div className="mt-5 text-center">
              <Button className="yellowBtn">Login</Button>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default EmailConfirm;
