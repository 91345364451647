import axios from "axios";
import config from "../config";

/**
 * Search App from api
 * @param {string} appName
 */

export const getLeaderboardApi = (data: any) => {
  return axios({
    method: "get",
    url: `${config.apiBaseUrl}/webapi/leaderboard`,
    params: data,
  })
    .then((response) => response)
    .catch((err) => err.response);
};
