/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState } from "react";
import React, { useEffect } from "react";
import Logo from "../../assets/imgs/Header-Logo-Banner.png";
import AnonaLogo from "../../assets/imgs/Anona.png";
import decord from "../../assets/images/discord.svg";
import twitter from "../../assets/images/twitter.svg";
import { useLocation } from "react-router-dom";
import {
  Navbar,
  Button,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  Modal,
  ModalBody,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import { MetaConnectionContext } from "../../contexts/MetaConnectionContext";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { getCurrentUser, handleResponse } from "../../config/common";
import { setCurrentUser } from "../../config/common";
import {
  getAllKingDomsService,
  getHash,
  verifySignature,
} from "../../services/CommonServces";
import QRCodeComponent from "../../components/common/QRCodeComponent";
import { signMessage } from "../../utils";
import config from "../../config";
import { networks } from "../../config/network";

const PlayFabClient = require("playfab-sdk/Scripts/PlayFab/PlayFabClient.js");
const Web3 = require("web3");
const Web3EthContract = require("web3-eth-contract");
const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: "3f30c3d9a4794b6bac600ac401675dc8",
    },
  },
};
const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions, // required
});
let provider: any;
let web3: any;

const Completionist = () => (
  <h3 className="text-center">Qr code is expired.</h3>
);

const Header: React.FunctionComponent = () => {
  const location = useLocation();
  const [isOpenQrCodeModal, setIsOpenQrCodeModal] = useState(false);
  const [isQrCodeExpired, setIsQrCodeExpired] = useState(false);
  const [minutes] = useState<number>(2);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState<string>("");
  const [qrCode, setQRCdoe] = useState<string>("");
  const {
    address,
    setAddress,
    setBalance,
    setWalletIsConnected,
    setWeb3Configured,
    walletIsConnected
  } = useContext(MetaConnectionContext);
  // { address, setAddress, setBalance, setWalletIsConnected, setWeb3Configured }
  // const homeHeader = window.location.pathname === "/" ? "home-header" : "";
  // Collapse Open state
  const [isOpen, setIsOpen] = React.useState(false);
  // const navigate = useNavigate();

  // const TanjeaTheme = async () => {
  //   var element = document.body;
  //   element.classList.toggle("tanjeaTheme");
  //   isLight(!light);
  //   if (theme._id === "") {
  //     if (!localStorage.getItem("pubco/kingdon")) {
  //       const res = await getAllKingDomsService();
  //       const response = await handleResponse(res);
  //       if (response.success) {
  //         const { kingdoms } = response.data;
  //         if (kingdoms.length > 0) {
  //           setTheme({ _id: kingdoms[0]._id, name: kingdoms[0].name });
  //         }
  //       }
  //     } else {
  //       const kingdomList = JSON.parse(
  //         localStorage.getItem("pubco/kingdon") ?? ""
  //       );
  //       if (kingdomList && kingdomList.length > 0) {
  //         setTheme({ _id: kingdomList[0]._id, name: kingdomList[0].name });
  //       }
  //     }
  //   } else {
  //     setTheme({ _id: "", name: "Tanjea" });
  //   }
  // };

  /**
   * Get connected account data
   */
  const fetchAccountData = async () => {
    // Get a Web3 instance for the wallet
    web3 = new Web3(provider);
    Web3EthContract.setProvider(provider);
    // Get list of accounts of the connected wallet
    const accounts = await web3.eth.getAccounts();
    // MetaMask does not give you all accounts, only the selected account
    let selectedAccount = accounts[0];
    const balanceInWei = await web3.eth.getBalance(selectedAccount);

    const balance = web3.utils.fromWei(balanceInWei, "ether");
    localStorage.setItem("pubco/currentAccount", selectedAccount);
    PlayFabClient.settings.titleId = "B9046";
    let _Request = {
      CustomId: selectedAccount,
      CreateAccount: true,
      TitleId: PlayFabClient.settings.titleId,
    };
    PlayFabClient.LoginWithCustomID(_Request, (err: any, data: any) => {
      if (data) {
        setCurrentUser(data.data);
        setBalance(balance);
        setAddress(selectedAccount);
        setWalletIsConnected(true);
      }
    });
  };

  /**
   * Refresh account data
   */
  const refreshAccountData = async () => {
    await fetchAccountData();
  };

  const changeNetwork = async (networkName: string) => {
    let isValid = true;
    try {
      if (!window.ethereum) throw new Error("No crypto wallet found");
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: networks[networkName].chainId }],
      });
      isValid = true;
    } catch (switchError: any) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                ...networks[networkName],
              },
            ],
          });
          isValid = true;
        } catch (addError) {
          console.log(addError);
          isValid = false;
          // handle "add" error
        }
      } else {
        isValid = false;
      }
    }
    return isValid;
  };

  const connectWalletToWeb3 = async () => {
    let accountsCopy = [];
    try {
      const web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      accountsCopy = await web3.eth.getAccounts();
      provider = await web3Modal.connect();
      Web3EthContract.setProvider(provider);
      await fetchAccountData();

      // Subscribe to accounts change
      provider.on("accountsChanged", () => {
        console.log("accoutns changes ");
        fetchAccountData();
      });
      // Subscribe to chainId change
      provider.on("chainChanged", (data: any) => {
        console.log("chain changes ", data);
        fetchAccountData();
      });
      // Subscribe to networkId change
      provider.on("networkChanged", (data: any) => {
        console.log("network changes ", data);
        if (data !== 1 && data !== 4) {
          handleDisconnectWallet();
          return false;
        } else {
          console.log("network changes ", data);
          fetchAccountData();
        }
      });
      provider.on("connect", (info: any) => {
        console.log("connected ", info);
      });
      refreshAccountData();
    } catch (e) {
      if (accountsCopy && accountsCopy.length > 0) {
        setWeb3Configured(true);
      }
      // setWeb3Configured(true)
      console.log("Could not get a wallet connection", e);
      return;
    }
  };
  /**
   * Handle click event of `conneect wallet` button
   */
  const handleOnConnect = async () => {
    try {
      let isSwitch = true;
      if (config.siteMode === "live") {
        isSwitch = await changeNetwork("ethereum");
      } else if (config.siteMode === "test") {
        isSwitch = await changeNetwork("rinkeby");
      }

      if (isSwitch) {
        connectWalletToWeb3();
      }
    } catch (e) {
      // setWeb3Configured(true)
      console.log("Could not get a wallet connection", e);
      return;
    }
  };

  const handleDisconnectWallet = async () => {
    await web3Modal.clearCachedProvider();
    localStorage.removeItem("pubco/currentAccount");
    setBalance(0);
    setAddress("");
    setWalletIsConnected(false);
  };
  const getAllKingDoms = async () => {
    const res = await getAllKingDomsService();
    const response = await handleResponse(res);
    if (response.success) {
      const { kingdoms } = response.data;
      if (kingdoms.length > 0) {
        localStorage.setItem("pubco/kingdon", JSON.stringify(kingdoms));
      }
    }
  };

  const handleQrModal = () => {
    setIsOpenQrCodeModal(!isOpenQrCodeModal);
  };
  // const handleLogout = async (event: any) => {
  //   event.preventDefault();
  //   await removeCurrentUser();
  //   setLoginWithEmail(false);
  //   navigate("/login");
  // };
  const handleOnComplete = () => {
    setIsQrCodeExpired(true);
  };

  const getQrCode = async (signature: any) => {
    try {
      const paramsRequest = {
        WalletAddress: address,
        Signature: signature.signature,
      };
      const verifiRes = await verifySignature(paramsRequest);
      const verifiResponse = await handleResponse(verifiRes);
      if (verifiResponse.success) {
        const { data } = verifiResponse;

        if (data) {
          setQRCdoe(data.qrCodeData);
          setLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleQrCodeBtnEvent = async () => {
    setLoading(true);
    setIsQrCodeExpired(false);
    setIsOpenQrCodeModal(true);
    try {
      const params = {
        WalletAddress: address,
      };
      const res = await getHash(params);
      const response = await handleResponse(res);
      if (response.success) {
        const { data } = response;
        if (data) {
          const sig = await signMessage({
            setError,
            message: data.hash,
          });
          if (sig) {
            getQrCode(sig);
          }
        }
      }
      console.log(response, "response");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const user = getCurrentUser();
    if (!user.token) {
      // handleOnConnect();
    } else {
      setAddress(user.address ?? "");
      setWalletIsConnected(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    getAllKingDoms();
    if(walletIsConnected){
      window.ethereum.on("accountsChanged", function (accounts: string[]) {
        localStorage.setItem("pubco/currentAccount", accounts[0]);
        setAddress(accounts[0]);
      });
    }
  }, []);

  return (
    <header className="siteNav home-header">
      <div className="container">
        <Navbar expand="md" className="mainNavbar">
          <NavbarBrand href="/">
            <div className="headerLogo_img">
              <img src={Logo} alt="Logo" className="headerLogo tanjeaShow" />
              <img
                src={AnonaLogo}
                alt="Logo"
                className="headerLogo anonaShow headerlogo"
              />
            </div>

            {/* <label className="iosSwitch">
            <input type="checkbox" onClick={() => TanjeaTheme()} />
            <div></div>
          </label> */}
          </NavbarBrand>
          <NavbarToggler
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
          <Collapse navbar isOpen={isOpen}>
            <div className="mobMenuHeader">
              <img
                src={AnonaLogo}
                alt="Logo"
                className="headerLogo anonaShow"
              />
              <NavbarBrand to="/">
                <img src={Logo} alt="Logo" className="headerLogo tanjeaShow" />
              </NavbarBrand>
              <Button
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                className="navbar-toggler closeBurgerMenu float-right"
              />
            </div>

            <Nav className="ml-auto customHeader justify-content-end" navbar>
              <div className="d-flex CustomNavLink CustomNavLinkli">
                <li>
                  <NavLink to="/jea-explorer">Jea Explorer</NavLink>
                </li>
                <li>
                  <NavLink to="/marketplace/land-chest-sale">Mint</NavLink>
                </li>
                <li>
                  <NavLink to="/battleboards">Battleboards</NavLink>
                </li>
                <li>
                  <a
                    href="https://tanjea.gitbook.io/untitled"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Whitepaper
                  </a>
                </li>
              </div>

              <div className="d-flex CustomNavLink align-items-center">
                <a
                  href="https://twitter.com/tanjeaverse"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitter} className="mobilehidden" alt="" />
                </a>
                <a
                  className="nav-link customNavLink"
                  href={config.discordLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={decord} className="ml-3 mobilehidden" alt="" />
                </a>
                <NavItem className="headerLogin ml-xl-4">
                  {!address ? (
                    <span
                      className="nav-link"
                      onClick={handleOnConnect}
                      id="btn-connect"
                    >
                      Connect Wallet
                    </span>
                  ) : (
                    <span className="nav-link" onClick={handleDisconnectWallet}>
                      Disconnect
                    </span>
                  )}
                </NavItem>
                {address ? (
                  <Button
                    className="qr-code-btn"
                    onClick={handleQrCodeBtnEvent}
                  >
                    <i
                      className="fa fa-qrcode text-dark"
                      aria-hidden="true"
                    ></i>
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </Nav>
          </Collapse>
        </Navbar>
        <Modal
          isOpen={isOpenQrCodeModal}
          toggle={handleQrModal}
          centered={true}
          size="lg"
          className="qr-code-instruction"
        >
          <ModalBody>
            <div className="d-block">
              {!isQrCodeExpired ? (
                <QRCodeComponent
                  loading={loading}
                  qrCode={qrCode}
                  handleOnComplete={handleOnComplete}
                  date={Date.now() + minutes * 60 * 1000}
                />
              ) : (
                <Completionist />
              )}
            </div>
          </ModalBody>
        </Modal>
      </div>
    </header>
  );
};

export default Header;
