import React from "react";
import decordheader from "../../assets/imgs/decordheader1.png";
import config from "../../config";

const PetBreeding = () => {
  return (
    <>
      <div className="bg_parpul sectionfourth">
        <div className="container">
          <div className="row text-center justify-content-center">
            <div className="col-lg-3">
              <p>
                Development<br></br> Updates!
              </p>
            </div>
            <div className="col-lg-3">
              <p>
                Join Our<br></br>
                Discord!
              </p>
            </div>
            <div className="col-lg-3">
              <p>
                NFT Giveaway<br></br> Contests!
              </p>
            </div>
          </div>
          <div className="text-center pt-5">
            <a
              href={config.discordLink}
              target="_blank"
              className="btn yellowBtn"
              rel="noreferrer"
            >
              <img src={decordheader} className="mr-3" alt="" />
              Discord
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default PetBreeding;
