import { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Input, Button, Modal, ModalBody } from "reactstrap";
import BirdImg from "../../assets/imgs/Bird31.png";
import GoldCoin from "../../assets/imgs/gold-coin.svg";
import EtheruemLogo from "../../assets/imgs/etheruem-logo.svg";
import GoldLargeIcon from "../../assets/imgs/gold-large.svg";
import { Filter } from "../Filter";
import { MyAccountInfo } from "./MyAccountInfo";
import { PetList } from "../PetList";

const Breed = (props) => {
  // Fade Open state
  const [model3, setModel3] = useState(false);
  // const [model2, setModel2] = useState(false);
  const toggle = () => {
    setModel3(!model3);
    // setModel2(!model2)
  };
  return (
    <section className="myAccountPage innerPageTopSpace">
      <div className="petExchangeTopSec pt-0 pb-2">
        <MyAccountInfo />
        <div className="container">
          <div className="myaccountNav">
            <ul>
              <li>
                <Link className="nav-link" to="/my-account/inventory">
                  Inventory
                </Link>
              </li>
              <li>
                <Link className="nav-link active" to="/my-account/breed">
                  Breed
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="/my-account/my-scores">
                  My Scores
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="/my-account/listed-pets">
                  Listed Pets
                </Link>
              </li>
            </ul>
          </div>
          <div className="breedTopSec">
            <Row>
              <Col md="4" sm="12" className="order-md-2">
                <div className="breedMidDataSec">
                  <div>
                    <Input
                      className="form-control customSelectBox"
                      name="select"
                      type="select"
                    >
                      <option>Bird</option>
                      <option>Wolf</option>
                      <option>Sharktopus</option>
                    </Input>
                  </div>
                  <img
                    src={require("../../assets/imgs/quesIcon.svg").default}
                    className="questionMarkImg"
                    alt=""
                  />
                  <div className="bmdBtmSec">
                    <h5>Breed 1</h5>
                    <div className="bmdIcons">
                      <img
                        onClick={() => toggle("model3")}
                        src={GoldCoin}
                        alt=""
                      />
                      <img
                        onClick={() => toggle("model2")}
                        src={EtheruemLogo}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="4" sm="6" className="breedTopSpaceSec order-md-1">
                <div className="birdImg">
                  <img src={BirdImg} alt="birdImg" className="img-fluid" />
                </div>
                <div className="petExchangeTopSecData">
                  <h1 className="pageTitle text-left">pet name here</h1>
                  <ul>
                    <li>
                      <label>Stamina :</label>
                      <strong>#lorem</strong>
                    </li>
                    <li>
                      <label>Strength :</label>
                      <strong>#lorem</strong>
                    </li>
                    <li>
                      <label>Morale :</label>
                      <strong>#lorem</strong>
                    </li>
                    <li>
                      <label>Intellect :</label>
                      <strong>#lorem</strong>
                    </li>
                    <li>
                      <label>Speed :</label>
                      <strong>#lorem</strong>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md="4" sm="6" className="breedTopSpaceSec order-md-3">
                <div className="addBreed">
                  <Input type="file" />
                  <label className="uploadFile"></label>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <div className="petExchangeBtmSec">
        <div className="container">
          <Row>
            <Filter />
            <Col lg="9" md="8">
              <PetList />
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        isOpen={model3}
        toggle={() => toggle("model3")}
        className="modal-md spendGoldModel"
      >
        <ModalBody>
          <div className="modelInnerData">
            <img src={GoldLargeIcon} alt="gold-large" />
            <h5>Spend 1 Gold to Breed</h5>
            <div className="btnGroup">
              <Button className="yellowBtn" onClick={() => toggle("model3")}>
                Okay
              </Button>
              <Button className="greyLineBtn mt-3">Cancel</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* <Modal isOpen={model2} toggle={() => toggle("model2")} className="modal-md spendGoldModel">
          <ModalBody> 
            <div className="modelInnerData">          
            <img src={MoreGoldLargeIcon} alt='more-gold-large' />
            <h5>You need more Gold to Breed</h5>
          <div className="btnGroup">
            <Button className="yellowBtn" onClick={() => toggle("model3")}>Okay</Button>
          </div>
          </div> 
          </ModalBody>
        </Modal> */}
    </section>
  );
};

export default Breed;
