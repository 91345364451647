/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/imgs/Header-Logo-Banner.png";
import AnonaLogo from "../../assets/imgs/Anona.png";
import Newsletter from "../../assets/imgs/newsletter.svg";
import twitter from "../../assets/imgs/twitter.png";
import discords from "../../assets/imgs/descords.png";
import insta from "../../assets/imgs/insta.png";

import TanjeaNewsletter from "../../assets/imgs/tanjeaNewsletter.svg";
import { Row, Col } from "reactstrap";
import config from "../../config";

const Footer: FC = () => {
  const homeFooter = window.location.pathname === "/" ? "home-footer" : "";
  return (
    <footer className={`footer ${homeFooter}`}>
      <div className="container">
        <Row>
          <Col lg="3" className="mb-md-0 mb-3">
            <img
              src={Logo}
              alt="Logo"
              className="footer__logo tanjeaShow"
              width="100%"
            />
            <img
              src={AnonaLogo}
              alt="Logo"
              className="footer__logo anonaShow"
            />
            <div className="d-flex my-4 justify-content-center">
              <a
                href="https://twitter.com/tanjeaverse"
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitter} className="social_media" alt="" />
              </a>
              <a href={config.discordLink} target="_blank" rel="noreferrer">
                <img src={discords} className="social_media" alt="" />
              </a>
              <a
                href="https://www.instagram.com/tanjeaverse/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={insta} className="social_media" alt="" />
              </a>
            </div>
          </Col>
          <Col lg="2" md="3" className="pl-lg-5">
            <ul className="footerLinks">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              {/* <li><NavLink to="/marketplace/pet-exchange">Pet Exchange</NavLink></li> */}
              <li>
                <a href={config.discordLink} target="_blank" rel="noreferrer">
                  Join Discord
                </a>
              </li>
              <li id="ShowHide">
                <a
                  href="https://tanjea.gitbook.io/untitled/team"
                  target="_blank"
                  className="ShowHide"
                  rel="noreferrer"
                >
                  About US
                </a>
              </li>
            </ul>
          </Col>
          <Col lg="2" md="3">
            <ul className="footerLinks">
              <li>
                <NavLink to="/jea-explorer">Jea Explorer</NavLink>
              </li>
              <li>
                <NavLink to="/marketplace/land-chest-sale">Mint</NavLink>
              </li>
              <li className="removelink">
               <a href="#privacy_policy_main"><NavLink to="/privacy-policy">Privacy Policy</NavLink></a>
              </li>
              <li className="removelink">
                <a href="https://raritysniper.com/nft-drops-calendar">Rarity Sniper Drops
                </a>
              </li>
            </ul>
          </Col>
          <Col lg="2" md="3">
            <ul className="footerLinks">
              <li>
                <NavLink to="/battleboards">Battleboards</NavLink>
              </li>
              <li>
                <a
                  href="https://tanjea.gitbook.io/untitled"
                  target="_blank"
                  rel="noreferrer"
                >
                  Whitepaper
                </a>
              </li>
            </ul>
          </Col>
          <Col lg="2" md="3" className="remove">
            <ul className="footerLinks">
            <li className="fullwidthlink">
              
              <a href="#privacy_policy_main"><NavLink to="/privacy-policy">Privacy Policy</NavLink></a>
             </li>

            </ul>
          </Col>
          <Col lg="2" md="3" className="remove">
            <ul className="footerLinks d-flex">
              <li>
                <a href="https://raritysniper.com/nft-drops-calendar">Rarity Sniper Drops
                </a>
              </li>
            </ul>
          </Col>
          

          {/* <Col lg="3" md="3" className="pl-lg-5 hideShow">
            <ul className="footerLinks">
              <li>
                <a
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                >
                  About US
                </a>
              </li>
            </ul>
          </Col> */}
          <Col lg="3" md="3">
            <div className="newsletterSec">
              <img
                src={Newsletter}
                alt="newsletter"
                className="newsletterImg anonaShow"
              />
              <img
                src={TanjeaNewsletter}
                alt="newsletter"
                className="newsletterImg tanjeaShow"
              />
              {/* <Form>
                <Input id="" name="text" placeholder="Your email address" />
                <Button className="yellowBtn">Subscribe!</Button>
              </Form> */}
              {/*  Begin Mailchimp Signup Form*/}

              <div id="mc_embed_signup">
                <form
                  action="https://tanjea.us14.list-manage.com/subscribe/post?u=e8522a4713fa73b0936caef4c&amp;id=eda279f8d1"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  className="validate"
                  target="_blank"
                  noValidate
                >
                  <div id="mc_embed_signup_scroll">
                    {/* <div className="indicates-required">
                      <span className="asterisk">*</span> indicates required
                    </div> */}
                    <div className="mc-field-group">
                      <input
                        type="email"
                        name="EMAIL"
                        id="mce-EMAIL"
                        className="required email"
                        placeholder="Your email address"
                        required={true}
                      />
                    </div>
                    <div id="mce-responses" className="clear foot">
                      <div
                        className="response hide"
                        id="mce-error-response"
                      ></div>
                      <div
                        className="response hide"
                        id="mce-success-response"
                      ></div>
                    </div>
                    {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                    <div className="positions" aria-hidden="true">
                      <input
                        type="text"
                        name="b_e8522a4713fa73b0936caef4c_eda279f8d1"
                        tabIndex={-1}
                        value=""
                      />
                    </div>
                    <div className="optionalParent">
                      <div className="clear foot">
                        <input
                          type="submit"
                          value="Subscribe!"
                          name="subscribe"
                          id="mc-embedded-subscribe"
                          className="button1"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/* <div className="Discord">
                <a
                  href={config.discordLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img src={Discord} alt="" />
                </a>
              </div> */}

              {/* End mc_embed_signup*/}
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
