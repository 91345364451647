export const abi = [
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"id",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"bytes",
            "name":"blueprint",
            "type":"bytes"
         }
      ],
      "name":"AssetMinted",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"contract IERC20Upgradeable",
            "name":"token",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"ERC20PaymentReleased",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"shares",
            "type":"uint256"
         }
      ],
      "name":"PayeeAdded",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"PaymentReceived",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"PaymentReleased",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
         },
         {
            "indexed":true,
            "internalType":"bytes32",
            "name":"previousAdminRole",
            "type":"bytes32"
         },
         {
            "indexed":true,
            "internalType":"bytes32",
            "name":"newAdminRole",
            "type":"bytes32"
         }
      ],
      "name":"RoleAdminChanged",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"account",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"sender",
            "type":"address"
         }
      ],
      "name":"RoleGranted",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"account",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"sender",
            "type":"address"
         }
      ],
      "name":"RoleRevoked",
      "type":"event"
   },
   {
      "inputs":[
         
      ],
      "name":"ADMIN_ROLE",
      "outputs":[
         {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"DEFAULT_ADMIN_ROLE",
      "outputs":[
         {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"SERVER_MINTER_ROLE",
      "outputs":[
         {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"blueprints",
      "outputs":[
         {
            "internalType":"bytes",
            "name":"",
            "type":"bytes"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"finalizeReveal",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"projectAddress",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "internalType":"string",
            "name":"tokenMintPassTypeStr",
            "type":"string"
         }
      ],
      "name":"getMintByTokenPasses",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
         }
      ],
      "name":"getRoleAdmin",
      "outputs":[
         {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
         },
         {
            "internalType":"uint256",
            "name":"index",
            "type":"uint256"
         }
      ],
      "name":"getRoleMember",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
         }
      ],
      "name":"getRoleMemberCount",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"string",
            "name":"tokenMintPassTypeStr",
            "type":"string"
         }
      ],
      "name":"getSupplyAtStartTime",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"string",
            "name":"tokenMintPassTypeStr",
            "type":"string"
         }
      ],
      "name":"getTokenMintPassActive",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"string",
            "name":"tokenMintPassTypeStr",
            "type":"string"
         },
         {
            "internalType":"uint256",
            "name":"tokenIndex",
            "type":"uint256"
         }
      ],
      "name":"getTokenMintPassStatus",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"string",
            "name":"tokenMintPassTypeStr",
            "type":"string"
         },
         {
            "internalType":"uint256",
            "name":"tokenIndex",
            "type":"uint256"
         }
      ],
      "name":"getTokenMintPassTracker",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"projectAddress",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenIndex",
            "type":"uint256"
         }
      ],
      "name":"getTokenWhitelistStatus",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"projectAddress",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"user",
            "type":"address"
         }
      ],
      "name":"getWhitelistTokens",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
         },
         {
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"grantRole",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
         },
         {
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"hasRole",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"sender",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"numberOfTokens",
            "type":"uint256"
         },
         {
            "internalType":"bytes32",
            "name":"nonce",
            "type":"bytes32"
         }
      ],
      "name":"hashTransaction",
      "outputs":[
         {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"sender",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"numberOfTokens",
            "type":"uint256"
         },
         {
            "internalType":"bytes32",
            "name":"nonce",
            "type":"bytes32"
         },
         {
            "internalType":"string",
            "name":"purpose",
            "type":"string"
         }
      ],
      "name":"hashTransactionWithPurpose",
      "outputs":[
         {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"imx",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_tokenContract",
            "type":"address"
         },
         {
            "internalType":"address[]",
            "name":"payees",
            "type":"address[]"
         },
         {
            "internalType":"uint256[]",
            "name":"shares_",
            "type":"uint256[]"
         }
      ],
      "name":"initialize",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"marketTaxTimesTen",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"numberOfTokens",
            "type":"uint256"
         }
      ],
      "name":"mint",
      "outputs":[
         
      ],
      "stateMutability":"payable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"quantity",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"mintingBlob",
            "type":"bytes"
         }
      ],
      "name":"mintFor",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"mintSigner",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address[]",
            "name":"recipients",
            "type":"address[]"
         },
         {
            "internalType":"uint256[]",
            "name":"numEach",
            "type":"uint256[]"
         }
      ],
      "name":"mintTo",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"numberOfTokens",
            "type":"uint256"
         }
      ],
      "name":"mintTokenUsingToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"minterActive",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"mintsPerToken",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"index",
            "type":"uint256"
         }
      ],
      "name":"payee",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"paymentToken",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"projectTokenNeeded",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"provenance",
      "outputs":[
         {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"registry",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address payable",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"release",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"contract IERC20Upgradeable",
            "name":"token",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"release",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"contract IERC20Upgradeable",
            "name":"token",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"released",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"released",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
         },
         {
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"renounceRole",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"num",
            "type":"uint256"
         }
      ],
      "name":"reserveTokens",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"revealTimestamp",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
         },
         {
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"revokeRole",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"imxAddress",
            "type":"address"
         }
      ],
      "name":"setImx",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"whitelistAddress",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenMintVal",
            "type":"uint256"
         }
      ],
      "name":"setMintPass",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"signer",
            "type":"address"
         }
      ],
      "name":"setMintSigner",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bool",
            "name":"status",
            "type":"bool"
         }
      ],
      "name":"setMinterActive",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"token",
            "type":"address"
         }
      ],
      "name":"setPaymentToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes32",
            "name":"provenanceHash",
            "type":"bytes32"
         }
      ],
      "name":"setProvenance",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"timestamp",
            "type":"uint256"
         }
      ],
      "name":"setRevealTime",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"string",
            "name":"tokenMintPassTypeStr",
            "type":"string"
         },
         {
            "internalType":"uint256",
            "name":"currentSupply",
            "type":"uint256"
         }
      ],
      "name":"setSupplyAtStartTime",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tax",
            "type":"uint256"
         }
      ],
      "name":"setTax",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"string",
            "name":"tokenMintPassTypeStr",
            "type":"string"
         },
         {
            "internalType":"bool",
            "name":"status",
            "type":"bool"
         }
      ],
      "name":"setTokenMintPassActive",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"string",
            "name":"tokenMintPassTypeStr",
            "type":"string"
         },
         {
            "internalType":"bool",
            "name":"turnOffLastPass",
            "type":"bool"
         }
      ],
      "name":"setTokenMintPassType",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"shares",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"numberOfTokens",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"maxPermitted",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"signature",
            "type":"bytes"
         },
         {
            "internalType":"bytes32",
            "name":"nonce",
            "type":"bytes32"
         },
         {
            "internalType":"string",
            "name":"purpose",
            "type":"string"
         }
      ],
      "name":"signedByServerMint",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"numberOfTokens",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"maxPermitted",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"signature",
            "type":"bytes"
         },
         {
            "internalType":"bytes32",
            "name":"nonce",
            "type":"bytes32"
         }
      ],
      "name":"signedMint",
      "outputs":[
         
      ],
      "stateMutability":"payable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"startingIndex",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"startingIndexBlock",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes4",
            "name":"interfaceId",
            "type":"bytes4"
         }
      ],
      "name":"supportsInterface",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tokenContract",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tokenMintPassType",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"contract IERC20Upgradeable",
            "name":"token",
            "type":"address"
         }
      ],
      "name":"totalReleased",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"totalReleased",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"totalShares",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"numberOfTokens",
            "type":"uint256"
         }
      ],
      "name":"whiteListByTokenMint",
      "outputs":[
         
      ],
      "stateMutability":"payable",
      "type":"function"
   },
   {
      "stateMutability":"payable",
      "type":"receive"
   }
];