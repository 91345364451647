import axios from "axios";
import config from "../config";

/**
 * Search App from api
 * @param {string} appName
 */

export const getAllKingDomsService = () => {
  return axios
    .post(`${config.apiBaseUrl}/WebApi/GetKingdoms`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const getWhiteListedDetailService = (data: {
  WalletAddress: string;
  chainId: number;
}) => {
  return axios
    .post(`${config.apiBaseUrl}/WebAPI/SignedMint`, data)
    .then((response) => response)
    .catch((error) => error.response);
};

export const getHash = (data: { WalletAddress: string }) => {
  return axios
    .post(`${config.apiBaseUrl}/WebAPI/GenerateHash`, data)
    .then((response) => response)
    .catch((error) => error.response);
};

export const verifySignature = (data: {
  WalletAddress: string;
  Signature: string;
}) => {
  return axios
    .post(`${config.apiBaseUrl}/WebAPI/VerifySignature`, data)
    .then((response) => response)
    .catch((error) => error.response);
};

export const changePassword = (data: {
  password: string;
  uniqueToken: string;
}) => {
  return axios
    .post(`${config.apiBaseUrl}/WebApi/changePassword`, data)
    .then((response) => response)
    .catch((error) => error.response);
};
