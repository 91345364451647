/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Input, Label } from "reactstrap";
import BirdImg from "../../assets/imgs/Bird31.png";
import { Filter } from "../Filter";
import { MyAccountInfo } from "./MyAccountInfo";
import { PetList } from "../PetList";

const ListedPets = () => {
  // Fade Open state
  // const [isOpen, setIsOpen] = React.useState(true);
  return (
    <section className="myAccountPage innerPageTopSpace">
      <div className="petExchangeTopSec pt-0">
        <MyAccountInfo />
        <div className="container">
          <div className="myaccountNav">
            <ul>
              <li>
                <Link className="nav-link" to="/my-account/inventory">
                  Inventory
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="/my-account/breed">
                  Breed
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="/my-account/my-scores">
                  My Scores
                </Link>
              </li>
              <li>
                <Link className="nav-link active" to="/my-account/listed-pets">
                  Listed Pets
                </Link>
              </li>
            </ul>
          </div>
          <Row>
            <Col md="4" sm="5">
              <div className="birdImg">
                <img src={BirdImg} alt="birdImg" className="img-fluid" />
              </div>
            </Col>
            <Col md="8" sm="7">
              <div className="petExchangeTopSecData">
                <h1 className="pageTitle text-left">pet name here</h1>
                <ul>
                  <li>
                    <label>Stamina :</label>
                    <strong>#lorem</strong>
                  </li>
                  <li>
                    <label>Strength :</label>
                    <strong>#lorem</strong>
                  </li>
                  <li>
                    <label>Morale :</label>
                    <strong>#lorem</strong>
                  </li>
                  <li>
                    <label>Intellect :</label>
                    <strong>#lorem</strong>
                  </li>
                  <li>
                    <label>Speed :</label>
                    <strong>#lorem</strong>
                  </li>
                </ul>
                <div className="petPriceSec">
                  <label>
                    Price : <strong>150</strong>
                  </label>
                  <div className="buyNowBtn">
                    <a href="#" className="btn yellowBtn">
                      List For Sale
                    </a>
                  </div>
                  <div className="buyNowBtn pt-3">
                    <a href="#" className="btn yellowBtn">
                      Remove From Market
                    </a>
                    <a href="#" className="btn greyLineBtn ml-3">
                      Change Price
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="petExchangeBtmSec">
        <div className="container">
          <Row>
            <Filter />
            <Col lg="9" md="8">
              <Row>
                <Col sm="6" md="4">
                  <div className="itemsSelectBox">
                    <div className="customCheckbox">
                      <Input
                        className="styled-checkbox"
                        name="check"
                        type="checkbox"
                        value="1"
                        id="MyListings"
                      />
                      <Label for="MyListings">My Listings</Label>
                    </div>
                  </div>
                </Col>
              </Row>
              <PetList />
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default ListedPets;
