import { FC } from "react";
import { Row, Col } from "reactstrap";
import BirdImg from "../../assets/imgs/Bird31.png";
import Stamina from "../../assets/icons/stamina.png";
import Speed from "../../assets/icons/speed1.png";
import Strength from "../../assets/icons/strength.png";
import Morale from "../../assets/icons/health.png";
import Intellect from "../../assets/icons/accuracy.png";

const scrollTop = () => {
  window.scrollTo(0, 0);
};
interface IPetList {
  onSelectItem: (pet: any) => void;
  petsList: any[];
  isBaseStats: string;
  totalPages: number;
  selectedId: string;
}
const PetList: FC<IPetList> = ({
  selectedId,
  petsList,
  totalPages,
  isBaseStats,
  onSelectItem,
}) => {
  return (
    <>
      <Row>
        {petsList &&
          petsList.map((petDetail, index) => (
            <Col
              sm="6"
              lg="4"
              key={index}
              onClick={() => onSelectItem(petDetail)}
            >
              <div
                className={`birdsCard ${
                  petDetail._id === selectedId ? "active" : ""
                }`}
                onClick={scrollTop}
              >
                <div className="border_hover">
                  <div className="birdsImgSec">
                    <img
                      src={petDetail?.petImage ?? BirdImg}
                      alt="birdImg"
                      className="img-fluid"
                    />
                  </div>
                  {/* <div className="birdsName">{petDetail.petName}</div> */}
                  <ul className="birdsallCards">
                    <li>
                      <img alt="" src={Stamina} width="20px" />
                      {isBaseStats === "base"
                        ? petDetail?.baseStats?.stamina ?? 0
                        : isBaseStats === "level"
                        ? petDetail?.levelStats?.stamina ?? 0
                        : Number(petDetail?.baseStats?.stamina ?? 0) +
                          Number(petDetail?.levelStats?.stamina ?? 0)}
                    </li>
                    <li>
                      <img alt="" src={Morale} width="20px" />
                      {isBaseStats === "base"
                        ? petDetail?.baseStats?.health ?? 0
                        : isBaseStats === "level"
                        ? petDetail?.levelStats?.health ?? 0
                        : Number(petDetail?.baseStats?.health ?? 0) +
                          Number(petDetail?.levelStats?.health ?? 0)}
                    </li>
                    <li>
                      <img alt="" src={Speed} width="20px" />
                      {isBaseStats === "base"
                        ? petDetail?.baseStats?.speed ?? 0
                        : isBaseStats === "level"
                        ? petDetail?.levelStats?.speed ?? 0
                        : Number(petDetail?.baseStats?.speed ?? 0) +
                          Number(petDetail?.levelStats?.speed ?? 0)}
                    </li>
                    <li>
                      <img alt="" src={Strength} width="20px" />
                      {isBaseStats === "base"
                        ? petDetail?.baseStats?.strength ?? 0
                        : isBaseStats === "level"
                        ? petDetail?.levelStats?.strength ?? 0
                        : Number(petDetail?.baseStats?.strength ?? 0) +
                          Number(petDetail?.levelStats?.strength ?? 0)}
                    </li>
                    <li>
                      <img alt="" src={Intellect} width="20px" />
                      {isBaseStats === "base"
                        ? petDetail?.baseStats?.accuracy ?? 0
                        : isBaseStats === "level"
                        ? petDetail?.levelStats?.accuracy ?? 0
                        : Number(petDetail?.baseStats?.accuracy ?? 0) +
                          Number(petDetail?.levelStats?.accuracy ?? 0)}
                    </li>
                    <li>
                      <label className="LV">LV</label> {petDetail?.level ?? 0}
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          ))}
      </Row>
    </>
  );
};

export default PetList;
