import React from "react";
import Countdown from "react-countdown-now";
import QRCode from "react-qr-code";
import Skeleton from "react-loading-skeleton";

// Renderer callback with condition
const renderer = ({ minutes, seconds, completed }: any) => {
  if (completed) {
    return "";
  } else {
    return (
      <span style={{ fontSize: "24px" }}>
        {minutes}:{String(seconds).padStart(2, "0")}
      </span>
    );
  }
};

const QRCodeComponent = ({ handleOnComplete, date, loading, qrCode }: any) => {
  return (
    <>
      <div className="row">
        <div className="col-7">
          <div className="instructions">
            <h2>To use Tanjea on your system :</h2>
            <ol className="instructions-list">
              <li>Open Tanjea on your phone </li>
              <li>
                Tap your avatar in the top-right window and select SYNC BLOCKCHAIN WALLET
              </li>
              <li>Point your phone to this screen to capture the code</li>
            </ol>
          </div>
        </div>
        <div className="col-5 text-center">
          {loading ? (
            <Skeleton height={256} />
          ) : (
            <>
              <QRCode value={qrCode ?? ""} />
              <p className="mt-2 mb-0 text-center timer-sec">
                Time Remaining:
                <Countdown
                  date={date}
                  renderer={renderer}
                  onComplete={handleOnComplete}
                />
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default QRCodeComponent;
