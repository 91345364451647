// import { Row, Col, Input, Table, Label } from "reactstrap";
// import BirdImg from "./../../assets/imgs/Bird31.png";
// import GoldCoin from "./../../assets/imgs/gold-coin.svg";
// import EtheruemLogo from "./../../assets/imgs/etheruem-logo.svg";
// import TblImg from "./../../assets/imgs/fox.png";
// import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const PetExchange = () => {
  return (
    <>
      <h2 className="CommingSoomLeader">Pet Exchange Coming Soon</h2>
      {/* <section className="petExchangePage innerPageTopSpace">
        <div className="petExchangeTopSec">
          <div className="container">
            <Row>
              <Col md="4" sm="5">
                <div className="birdImg">
                  <img src={BirdImg} alt="birdImg" className="img-fluid" />
                </div>
              </Col>
              <Col md="8" sm="7">
                <div className="petExchangeTopSecData">
                  <h1 className="pageTitle text-left">pet name here</h1>
                  <ul>
                    <li>
                      <label>Stamina :</label>
                      <strong>#lorem</strong>
                    </li>
                    <li>
                      <label>Strength :</label>
                      <strong>#lorem</strong>
                    </li>
                    <li>
                      <label>Morale :</label>
                      <strong>#lorem</strong>
                    </li>
                    <li>
                      <label>Intellect :</label>
                      <strong>#lorem</strong>
                    </li>
                    <li>
                      <label>Speed :</label>
                      <strong>#lorem</strong>
                    </li>
                  </ul>
                  <div className="petExchangeTopBuySec">
                    <div className="peBuyGroup">
                      <img
                        src={GoldCoin}
                        alt="GoldCoin"
                        className="img-fluid"
                      />
                      <img
                        src={EtheruemLogo}
                        alt="EtheruemLogo"
                        className="img-fluid"
                      />
                      <div>
                        Buy for <strong>150</strong>
                      </div>
                    </div>
                    <div className="buyNowBtn">
                      <a href="#" className="btn yellowBtn">
                        Buy Now
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="petExchangeBtmSec">
          <div className="container">
            <Row>
              <Col lg="3" md="4">
                <div className="filterCard">
                  <div className="filterTitle">
                    <strong>Filter</strong>
                    <div className="customCheckbox">
                      <Input
                        className="styled-checkbox"
                        name="check"
                        type="checkbox"
                        value="1"
                        id="Breedable"
                      />
                      <Label for="Breedable">Breedable</Label>
                    </div>
                  </div>
                  <div className="filterMainData">
                    <div className="radioGroups">
                      <h6>Pets</h6>
                      <div className="customRadio">
                        <Input
                          type="radio"
                          id="Wolf"
                          name="radio-group"
                          checked
                        />
                        <Label for="Wolf">Wolf</Label>
                      </div>
                      <div className="customRadio">
                        <Input type="radio" id="Bird" name="radio-group" />
                        <Label for="Bird">Bird</Label>
                      </div>
                      <div className="customRadio">
                        <Input
                          type="radio"
                          id="Sharktopus"
                          name="radio-group"
                        />
                        <Label for="Sharktopus">Sharktopus</Label>
                      </div>
                    </div>
                    <div className="radioGroups">
                      <h6>Heading</h6>
                      <div className="customRadio">
                        <Input
                          type="radio"
                          id="Adult"
                          name="radio-group"
                          checked
                        />
                        <Label for="Adult">Adult</Label>
                      </div>
                      <div className="customRadio">
                        <Input type="radio" id="Child" name="radio-group" />
                        <Label for="Child">Child</Label>
                      </div>
                    </div>
                    <div className="rangeSlider">
                      <label>Stamina</label>
                      <Slider
                        min={1}
                        defaultValue={5}
                        marks={{ 1: 1, 25: 2, 50: 3, 75: 4, 100: 5 }}
                      />
                    </div>
                    <div className="rangeSlider">
                      <label>Morale</label>
                      <Slider
                        min={1}
                        defaultValue={5}
                        marks={{ 1: 1, 25: 2, 50: 3, 75: 4, 100: 5 }}
                      />
                    </div>
                    <div className="rangeSlider">
                      <label>Speed</label>
                      <Slider
                        min={1}
                        defaultValue={5}
                        marks={{ 1: 1, 25: 2, 50: 3, 75: 4, 100: 5 }}
                      />
                    </div>
                    <div className="rangeSlider">
                      <label>Strength</label>
                      <Slider
                        min={1}
                        defaultValue={5}
                        marks={{ 1: 1, 25: 2, 50: 3, 75: 4, 100: 5 }}
                      />
                    </div>
                    <div className="rangeSlider mb-4">
                      <label>Intellect</label>
                      <Slider
                        min={1}
                        defaultValue={5}
                        marks={{ 1: 1, 25: 2, 50: 3, 75: 4, 100: 5 }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="9" md="8">
                <div className="petListTbl">
                  <Table borderless hover responsive>
                    <thead>
                      <tr>
                        <th>image</th>
                        <th>Name</th>
                        <th>stamina</th>
                        <th>Morale</th>
                        <th>speed</th>
                        <th>strength</th>
                        <th>intellect</th>
                        <th>price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          <img src={TblImg} className="animalsImg" />
                        </th>
                        <td>Wolf1</td>
                        <td>18</td>
                        <td>16</td>
                        <td>50</td>
                        <td>10</td>
                        <td>23</td>
                        <td>150</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <img src={TblImg} className="animalsImg" />
                        </th>
                        <td>Wolf1</td>
                        <td>18</td>
                        <td>16</td>
                        <td>50</td>
                        <td>10</td>
                        <td>23</td>
                        <td>150</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <img src={TblImg} className="animalsImg" />
                        </th>
                        <td>Wolf1</td>
                        <td>18</td>
                        <td>16</td>
                        <td>50</td>
                        <td>10</td>
                        <td>23</td>
                        <td>150</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <img src={TblImg} className="animalsImg" />
                        </th>
                        <td>Wolf1</td>
                        <td>18</td>
                        <td>16</td>
                        <td>50</td>
                        <td>10</td>
                        <td>23</td>
                        <td>150</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <img src={TblImg} className="animalsImg" />
                        </th>
                        <td>Wolf1</td>
                        <td>18</td>
                        <td>16</td>
                        <td>50</td>
                        <td>10</td>
                        <td>23</td>
                        <td>150</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <img src={TblImg} className="animalsImg" />
                        </th>
                        <td>Wolf1</td>
                        <td>18</td>
                        <td>16</td>
                        <td>50</td>
                        <td>10</td>
                        <td>23</td>
                        <td>150</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <img src={TblImg} className="animalsImg" />
                        </th>
                        <td>Wolf1</td>
                        <td>18</td>
                        <td>16</td>
                        <td>50</td>
                        <td>10</td>
                        <td>23</td>
                        <td>150</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <img src={TblImg} className="animalsImg" />
                        </th>
                        <td>Wolf1</td>
                        <td>18</td>
                        <td>16</td>
                        <td>50</td>
                        <td>10</td>
                        <td>23</td>
                        <td>150</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <img src={TblImg} className="animalsImg" />
                        </th>
                        <td>Wolf1</td>
                        <td>18</td>
                        <td>16</td>
                        <td>50</td>
                        <td>10</td>
                        <td>23</td>
                        <td>150</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default PetExchange;
