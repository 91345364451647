import React, { createContext, useState } from "react";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const Web3AlchemyKey = createAlchemyWeb3(alchemyKey);

interface ContextState {
  setAddress: (address: string) => void;
  setWeb3: (data: any) => void;
  setWeb3Configured: (data: boolean) => void;
  setBalance: (data: number) => void;
  setWalletIsConnected: (data: boolean) => void;
  setLoginWithEmail: (data:boolean)=> void;
  web3Configured: boolean;
  address: string;
  web3Context: any;
  balance: number;
  walletIsConnected: boolean;
  isLoginWithEmail: boolean
}
export const MetaConnectionContext = createContext({} as ContextState);

const MetaConnectionContextProvider = (props: any) => {
  const [address, setAddress] = useState<string>("");
  const [web3Context, setWeb3] = useState<any>(Web3AlchemyKey);
  const [web3Configured, setWeb3Configured] = useState<boolean>(false);
  const [balance, setBalance] = useState(0);
  const [walletIsConnected, setWalletIsConnected] = useState(false);
  const [isLoginWithEmail, setLoginWithEmail] = useState(false);

  const value = {
    setAddress,
    address,
    setWeb3,
    web3Context,
    web3Configured,
    setWeb3Configured,
    balance,
    walletIsConnected,
    setBalance,
    setWalletIsConnected,
    isLoginWithEmail,
    setLoginWithEmail
  };
  return <MetaConnectionContext.Provider value={value}>{props.children}</MetaConnectionContext.Provider>;
};

export default MetaConnectionContextProvider;
