import React from "react";
import { Footer } from "../../pages/Footer";
import { Header } from "../../pages/Header";

interface Props {
  children?: React.ReactNode;
}
const MainLayout = ({ children }: Props) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default MainLayout;
