/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { Row, Col, Button, Fade } from "reactstrap";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import IMG11 from "../../assets/imgs/landChest2.svg";
import IMG22 from "../../assets/imgs/landChest3.svg";
import IMG33 from "../../assets/imgs/landChest1.svg";
import IMG21 from "../../assets/imgs/Buildingrip1.svg";
import IMG32 from "../../assets/imgs/Buildingrip2.svg";
import JEA1 from "../../assets/images/jea1.png";
import JEA2 from "../../assets/images/jea2.png";
// import IMG43 from "../../assets/imgs/Buildingrip3.svg";
import IMG43 from "../../assets/imgs/Buildingrip33.svg";
import IMG44 from "../../assets/imgs/Buildingrip4.svg";
import { Tab, Nav, Spinner } from "react-bootstrap";
import Buildingbox from "../../assets/imgs/Building_Chest.png";
import BuildingboxOpen from "../../assets/imgs/BuildingboxOpen.png";
import JeaChest from "../../assets/imgs/Jea_Chest.png";
import LandChest from "../../assets/imgs/Land_Chest.png";
import ChestboxOpen from "../../assets/imgs/ChestboxOpen.png";
import JeaChestIMG from "../../assets/images/jeaImg.svg";
import { MetaConnectionContext } from "../../contexts/MetaConnectionContext";
import {
  abiFactory,
  contractAddressFactory,
} from "../../config/abi/abi-factory";
import { abi } from "../../config/abi/abi";
import { getWhiteListedDetailService } from "../../services/CommonServces";
import { handleResponse } from "../../config/common";
import { toast } from "react-toastify";
import PetBreeding from "../../components/HomeComponents/PetBreeding";
import MintTokenVal from "../../components/common/MintTokenVal";
import { abiProject } from "../../config/abi/abi-project";
//import ComingSoon from "../Common/ComingSoon";

const JeaChestItems = [
  <img src={JEA1} alt="img " className="landChest_sider_img" />,
  <img src={JEA2} alt="img " className="landChest_sider_img" />,
];
const Bhiuldingitems = [
  <img src={IMG11} alt="img " className="landChest_sider_img" />,
  <img src={IMG22} alt="img " className="landChest_sider_img" />,
  <img src={IMG33} alt="img " className="landChest_sider_img" />,
];

const items = [
  <img src={IMG21} alt="img " className="landChest_sider_img" />,
  <img src={IMG32} alt="img " className="landChest_sider_img" />,
  <img src={IMG43} alt="img " className="landChest_sider_img" />,
  <img src={IMG44} alt="img " className="landChest_sider_img" />,
];

const responsiveOne = {
  0: { items: 1 },
  767: { items: 1 },
};

const responsive = {
  0: { items: 1 },
  767: { items: 1 },
};

const projectAddress = "0x8D6De56C35C00c2F15282fFf64bA75C12f817927"; // "0xFD8723cA717d7E958C9d8DE196f8C73225cB7bBf"
const LandChestSale = () => {
  const { address, web3Context } = useContext(MetaConnectionContext);
  // Fade Open state
  const [title, setTitle] = useState<string>("JEA");
  const [loader, setLoader] = useState<boolean>(false);
  const [numberOfToken, setNumberOfToken] = useState<number>(1);
  const [transactionHash, setTransactionHash] = useState<string>("");
  const [error, setError] = useState<string>("");

  const [totalMinted, setTotalMonted] = useState<string>("");
  const [isOpen, setIsOpen] = useState(true);
  const [projectInfo, setProjectInfo] = useState<any>({});
  const contractFactory = new web3Context.eth.Contract(
    abiFactory,
    contractAddressFactory
  );
  const handleOnClick = (type: string) => {
    setTitle(type);
    setNumberOfToken(1);
    window.dispatchEvent(new Event("resize"));
  };

  const getTotalMinted = async (pAddress: string) => {
    const contractProject = new web3Context.eth.Contract(abiProject, pAddress);
    const totalMintedRes = await contractProject.methods.totalMinted().call();

    setTotalMonted(totalMintedRes);
  };

  /**
   * This function in not completed
   */
  const handleWhitelistNotTokenMint = async () => {
    try {
      const address = localStorage.getItem("pubco/currentAccount");
      if (address) {
        setError("");
        setLoader(true);
        const chainId = await new web3Context.eth.getChainId();
        const res = await getWhiteListedDetailService({
          WalletAddress: address.toLowerCase(),
          chainId,
        });
        const whiteListJson = await handleResponse(res);
        if (whiteListJson.success) {
          if (!whiteListJson.data.IsExpired) {
            const jsonData = whiteListJson.data.Message;
            if (jsonData) {
              const signature = jsonData.signature;
              const maxPermitted = jsonData.maxPermitted;
              const nonce = jsonData.nonce;
              const contract = new web3Context.eth.Contract(
                abi,
                projectInfo.projectMinterAddress
              );
              const projectDetail = await contractFactory.methods
                .projects(projectAddress)
                .call();
              const price = projectDetail["minting"]["price"] || "0";
              //should do something to tell user that cant mint more than maxPermitted if they try
              const totalPrice = numberOfToken * Number(price);
              contract.methods
                .signedMint(numberOfToken, maxPermitted, signature, nonce)
                .send({
                  from: address,
                  value: totalPrice,
                })
                .on("transactionHash", function (hash: string) {
                  console.log(hash);
                })
                .on(
                  "confirmation",
                  function (confirmationNumber: any, receipt: any) {
                    console.log(
                      confirmationNumber,
                      receipt,
                      "confirmationNumber, receipt"
                    );
                  }
                )
                .on("receipt", function (receipt: any) {
                  setLoader(false);
                  setTransactionHash(
                    "https://etherscan.io/tx/" + receipt.transactionHash
                  );
                  getTotalMinted(projectAddress);
                  console.log(receipt, "receipt");
                })
                .on("error", function (error: any, receipt: any) {
                  // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                  console.log("error: ", error);
                  setLoader(false);
                });
            }
          } else {
            toast(whiteListJson.message, {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            });
            setLoader(false);
            console.log(whiteListJson.message, "chainId");
          }
        } else {
          if (whiteListJson.data.status === 404) {
            setError("This wallet address is not whitelisted.");
          }
          setLoader(false);
        }
      }
    } catch (error) {
      console.error(error, "error");

      setLoader(false);
    }
  };

  /**
   * This function in not completed
   */
  const activeMint = async () => {
    try {
      const address = localStorage.getItem("pubco/currentAccount");

      if (address) {
        setError("");
        const contract = new web3Context.eth.Contract(
          abi,
          projectInfo.projectMinterAddress
        );
        const projectDetail = await contractFactory.methods
          .projects(projectAddress)
          .call();

        const price = projectDetail["minting"]["price"] || "0";
        const contractProject = new web3Context.eth.Contract(
          abiProject,
          projectAddress
        );
        const balanceOf = await contractProject.methods
          .balanceOf(address)
          .call();
        if (
          Number(balanceOf) >=
          Number(projectDetail["minting"]["maxWalletPurchase"])
        ) {
          setError("Minting limit has been reached.");
          return false;
        }
        if (
          Number(numberOfToken) >
          Number(projectDetail["minting"]["maxWalletPurchase"])
        ) {
          setError(
            "Mint amount must be less than max wallet purchase."
          );
          return false;
        }
        setLoader(true);
        //should do something to tell user that cant mint more than maxPermitted if they try
        const totalPrice = numberOfToken * Number(price);
        contract.methods
          .mint(numberOfToken)
          .send({
            from: address,
            value: totalPrice,
          })
          .on("transactionHash", function (hash: string) {
            console.log(hash);
          })
          .on("confirmation", function (confirmationNumber: any, receipt: any) {
            console.log(
              confirmationNumber,
              receipt,
              "confirmationNumber, receipt"
            );
          })
          .on("receipt", function (receipt: any) {
            setLoader(false);
            setTransactionHash(
              "https://etherscan.io/tx/" + receipt.transactionHash
            );
            getTotalMinted(projectAddress);
            console.log(receipt, "receipt");
          })
          .on("error", function (error: any, receipt: any) {
            // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
            console.log("error: ", error);
            setLoader(false);
            toast(
              "The transaction was rejected by the user.",
              {
                position: "bottom-right",
                autoClose: 10000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              }
            );
          });
      }
    } catch (error) {
      console.error(error, "error");
      toast(
        "The transaction was rejected by the user.",
        {
          position: "bottom-right",
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      setLoader(false);
    }
  };

  const handleOnChange = (value: number) => {
    setNumberOfToken(value);
  };

  useEffect(() => {
    const getProductInfo = async () => {
      try {
        const projectDetail = await contractFactory.methods
          .projects(projectAddress)
          .call();
        // console.log(projectDetail, "projectDetail");

        const projectPriceInWei = projectDetail["minting"]["price"] || "0";
        const projectPrice = web3Context.utils.fromWei(
          projectPriceInWei,
          "ether"
        );

        const project = {
          projectAddress: projectAddress,
          license: projectDetail["detail"]["license"] || "",
          name: projectDetail["detail"]["name"] || "",
          symbol: projectDetail["detail"]["symbol"] || "",
          isFreeMint: projectDetail["minting"]["isFreeMint"],
          maxBlockPurchase: projectDetail["minting"]["maxBlockPurchase"] || "",
          maxSupply: projectDetail["minting"]["maxSupply"] || "",
          maxWalletPurchase:
            projectDetail["minting"]["maxWalletPurchase"] || "",
          projectMinterAddress: projectDetail["minting"]["minter"] || "",
          priceInWei: projectPriceInWei,
          priceEth: projectPrice,
          status: projectDetail["minting"]["status"] || "",
        };

        setProjectInfo(project);
        getTotalMinted(projectAddress);
      } catch (error) {
        console.log(error);
      }
    };
    getProductInfo();
  }, [web3Context, address]);

  return (
    <>
      <section className="chestSalePage">
        <div className="filter_bg">
          <h3 className="pageTitle pt-5 pb-3 promo__title_decorative ">
            {title} chest sale
          </h3>
        </div>
        <Tab.Container id="left-tabs-example" defaultActiveKey="JeaChestTab">
          <div className="bg_yellow">
            <div className="chestCard container chestCardspece">
              <Row>
                <Col sm={12}>
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link eventKey="BuildingChestTab">
                        <div
                          className="chestCardCol"
                          onClick={() => handleOnClick("Building")}
                        >
                          <img
                            src={Buildingbox}
                            alt="building-chest"
                            className="img-fluid"
                          />
                          <h4>Building Chest</h4>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="JeaChestTab">
                        <div
                          className="chestCardCol"
                          onClick={() => handleOnClick("Jea")}
                        >
                          <img
                            src={JeaChest}
                            alt="building-chest"
                            className="img-fluid"
                          />
                          <h4>Jea Chest</h4>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="LandChestTab">
                        <div
                          className="chestCardCol"
                          onClick={() => handleOnClick("Land")}
                        >
                          <img
                            src={LandChest}
                            alt="land-chest"
                            className="img-fluid"
                          />
                          <h4>Land Chest</h4>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              </Row>
            </div>
          </div>
          <Tab.Content>
            <Tab.Pane eventKey="BuildingChestTab">
              <section className="chestDetailsTopSec">
                <div className="container">
                  <Row className="justify-content-center align-items-center">
                    <Col lg="4">
                      <img
                        src={BuildingboxOpen}
                        alt="land-chest"
                        className="img-fluid"
                      />
                    </Col>
                    <Col lg="4">
                      <div className="cDetailsTitle">
                        <h3>Building Chest</h3>

                        <ul className="white_list">
                          <li>5 buildings</li>
                          <li>At least one rare building</li>
                          <li>Chance at epic building</li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
              </section>
              <section className="chestDetailsBtmSec bg_yellow1 py-5">
                <div className="container justify-content-center">
                  <div className="chestImgSec">
                    <AliceCarousel
                      infinite={true}
                      items={items}
                      responsive={responsive}
                      // autoHeight={true}
                    ></AliceCarousel>
                  </div>

                  {/*<div className="chestDetailsSec">
                        <h4>Only 451 chests available</h4>
                        <ul>
                          <li>1 Plot of Land in the Forest Area</li>
                          <li>At least 10 Rare Items</li>
                          <li>Very low chance of Genesis Land</li>
                        </ul>
                          </div>*/}
                  <div className="chestPurchaseSec">
                    {/* <Button
                        //   color="default"
                        //   className="purchaseBtnToggle"
                        //   onClick={() => {
                        //     setIsOpen(!isOpen);
                        //   }}
                        // >
                        //   <span>Pay using</span>
                        //   <strong>
                        //     <img src={EthIcon} /> ETH
                        //   </strong>
                        // </Button>*/}
                    <Fade in={isOpen} className="purchaseToggleData">
                      <div>
                        <div className="mb-2">
                          <span className="price">
                            Price : <b>{0}Ξ</b>
                          </span>
                        </div>
                        <div className="mb-3">
                          Total Minted :{" "}
                          <b>
                            #{0} of {0} max
                          </b>
                        </div>
                      </div>
                      <MintTokenVal
                        projectStatus={projectInfo.status}
                        numberOfToken={numberOfToken}
                        onChange={handleOnChange}
                      />
                      <div className="totalPurchase d-flex justify-content-center align-items-center">
                        <Button className="btn yellowBtnFaded ml-3">
                          Sale Not Live
                        </Button>
                        {/* <div>
                          Total :<strong> 9 ETH</strong>
                        </div> */}
                      </div>
                    </Fade>
                    {transactionHash ? (
                      <div className="mt-3 text-center">
                        <h5 className="text-white">Mint Successful</h5>
                        <a
                          href={transactionHash}
                          className="border border-white text-white p-2 rounded"
                        >
                          Transaction Hash: {transactionHash || "null"}
                        </a>
                      </div>
                    ) : null}
                    {error ? (
                      <div className="mt-3 text-center">
                        <h5 className="text-white text-danger">
                          Mint Error : {error || "null"}
                        </h5>
                      </div>
                    ) : null}
                  </div>
                </div>
              </section>
            </Tab.Pane>
            <Tab.Pane eventKey="LandChestTab">
              <section className="chestDetailsTopSec">
                <div className="container">
                  <Row className="justify-content-center align-items-center">
                    <Col md="4">
                      <img
                        src={ChestboxOpen}
                        alt="ChestThreeImg"
                        className="img-fluid"
                      />
                    </Col>
                    <Col md="4">
                      <div className="cDetailsTitle">
                        <h3>land Chest</h3>
                        <ul className="white_list">
                          <li>1 plot of land</li>
                          <li>Chance mountain plot</li>
                          <li>Chance lake plot</li>
                          <li>Chance flat land plot</li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
              </section>
              <section className="chestDetailsBtmSec bg_yellow1 py-5">
                <div className="container py-3">
                  <div className="chestImgSec">
                    <AliceCarousel
                      infinite={true}
                      items={Bhiuldingitems}
                      responsive={responsiveOne}
                      // autoHeight={true}
                      // disableDotsControls={true}
                    ></AliceCarousel>
                  </div>

                  {/*<div className="chestDetailsSec">
                        <h4>Only 451 chests available</h4>
                        <ul>
                          <li>1 Plot of Land in the Forest Area</li>
                          <li>At least 10 Rare Items</li>
                          <li>Very low chance of Genesis Land</li>
                        </ul>
                      </div>*/}
                  <div className="chestPurchaseSec">
                    {/*<Button
                          color="default"
                          className="purchaseBtnToggle"
                          onClick={() => {
                            setIsOpen(!isOpen);
                          }}
                        >
                          <span>Pay using</span>
                          <strong>
                            <img src={EthIcon} /> ETH
                          </strong>
                        </Button>*/}
                    <Fade in={isOpen} className="purchaseToggleData">
                      <div>
                        <div className="mb-2">
                          <span className="price">
                            Price : <b>{0}Ξ</b>
                          </span>
                        </div>
                        <div className="mb-3">
                          Total Minted :{" "}
                          <b>
                            #{0} of {0} max
                          </b>
                        </div>
                      </div>
                      <MintTokenVal
                        projectStatus={projectInfo.status}
                        numberOfToken={numberOfToken}
                        onChange={handleOnChange}
                      />
                      <div className="totalPurchase d-flex align-items-center justify-content-center">
                        <Button className="btn yellowBtnFaded ml-3">
                          Sale Not Live
                        </Button>
                        {/* <div>
                          Total :<strong> 9 ETH</strong>
                        </div> */}
                      </div>
                    </Fade>
                    {transactionHash ? (
                      <div className="mt-3 text-center">
                        <h5 className="text-white">Mint Successful</h5>
                        <a
                          href={transactionHash}
                          className="border border-white text-white p-2 rounded"
                        >
                          Transaction Hash: {transactionHash || "null"}
                        </a>
                      </div>
                    ) : null}
                    {error ? (
                      <div className="mt-3 text-center">
                        <h5 className="text-white text-danger">
                          Mint Error : {error || "null"}
                        </h5>
                      </div>
                    ) : null}
                  </div>
                </div>
              </section>
            </Tab.Pane>
            <Tab.Pane eventKey="JeaChestTab">
              <section className="chestDetailsTopSec">
                <div className="container">
                  <Row className="justify-content-center align-items-center">
                    <Col md="4">
                      <img
                        src={JeaChestIMG}
                        alt="ChestThreeImg"
                        className="img-fluid"
                      />
                    </Col>
                    <Col md="4">
                      <div className="cDetailsTitle">
                        <h3>Jea Chest</h3>
                        <ul className="white_list">
                          <li>Mint Rare Glidonn NFTs</li>
                          <li>
                            Collect or trade your Jea NFTs on the Ethereum
                            Network
                          </li>
                          <li>
                            Train and level up your Jea and compete with it to
                            win TNJ tokens!
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
              </section>
              <section className="chestDetailsBtmSec bg_yellow1 py-5">
                <div className="container py-3">
                  <div className="chestImgSec">
                    <AliceCarousel
                      infinite={true}
                      items={JeaChestItems}
                      responsive={responsiveOne}
                      // autoHeight={true}
                      // disableDotsControls={true}
                    ></AliceCarousel>
                  </div>

                  {/*<div className="chestDetailsSec">
                        <h4>Only 451 chests available</h4>
                        <ul>
                          <li>1 Plot of Land in the Forest Area</li>
                          <li>At least 10 Rare Items</li>
                          <li>Very low chance of Genesis Land</li>
                        </ul>
                      </div>*/}
                  <div className="chestPurchaseSec">
                    {/*<Button
                          color="default"
                          className="purchaseBtnToggle"
                          onClick={() => {
                            setIsOpen(!isOpen);
                          }}
                        >
                          <span>Pay using</span>
                          <strong>
                            <img src={EthIcon} /> ETH
                          </strong>
                        </Button>*/}
                    <Fade in={isOpen} className="purchaseToggleData">
                      <div>
                        <div className="mb-2">
                          <span className="price">
                            Price : <b>{projectInfo.priceEth || 0}Ξ</b>
                          </span>
                        </div>
                        <div className="mb-3">
                          Total Minted :{" "}
                          <b>
                            #{totalMinted || 0} of {projectInfo.maxSupply || 0}{" "}
                            max
                          </b>
                        </div>
                        <div className="mb-4">
                          Max Wallet Purchase :{" "}
                          <b>{projectInfo.maxWalletPurchase || 0} </b>
                        </div>
                      </div>
                      <MintTokenVal
                        projectStatus={projectInfo.status}
                        numberOfToken={numberOfToken}
                        onChange={handleOnChange}
                      />
                      <div className="totalPurchase d-flex align-items-center justify-content-center">
                        {address && projectInfo.status === "1" ? (
                          !transactionHash ? (
                            projectInfo.maxSupply !== totalMinted ? (
                              <Button
                                className="btn yellowBtn ml-3 btn_yg"
                                onClick={handleWhitelistNotTokenMint}
                              >
                                {loader ? (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  ""
                                )}{" "}
                                Whitelist Mint
                              </Button>
                            ) : (
                              <button className="btn btn-info btn-primary-custom">
                                Sold Out
                              </button>
                            )
                          ) : (
                            <Button className="btn yellowBtn ml-3 btn_yg">
                              <i className="fa fa-check-circle"></i> Mint
                              Successful
                            </Button>
                          )
                        ) : address && projectInfo.status === "2" ? (
                          !transactionHash ? (
                            <Button
                              className="btn yellowBtn ml-3 btn_yg"
                              onClick={activeMint}
                            >
                              {loader ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : (
                                ""
                              )}{" "}
                              Mint
                            </Button>
                          ) : (
                            <Button className="btn yellowBtn ml-3 btn_yg">
                              <i className="fa fa-check-circle"></i> Mint
                              Successful
                            </Button>
                          )
                        ) : address && projectInfo.status === "0" ? (
                          <Button className="btn yellowBtn ml-3 btn_yg">
                            Sale Not Active
                          </Button>
                        ) : (
                          <Button
                            className="btn yellowBtn ml-3 btn_yg"
                            onClick={() =>
                              document.getElementById("btn-connect")?.click()
                            }
                          >
                            Connect Wallet
                          </Button>
                        )}
                        {/* <div>
                          Total :<strong> 9 ETH</strong>
                        </div> */}
                      </div>
                    </Fade>
                    {transactionHash ? (
                      <div className="mt-3 text-center">
                        <h5 className="text-white">Mint Successful</h5>
                        <a
                          href={transactionHash}
                          className="border border-white text-white p-2 rounded"
                        >
                          Transaction Hash: {transactionHash || "null"}
                        </a>
                      </div>
                    ) : null}
                    {error ? (
                      <div className="mt-3 text-center">
                        <h5 className="text-white text-danger">
                          Mint Error : {error || "null"}
                        </h5>
                      </div>
                    ) : null}
                  </div>
                </div>
              </section>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        <PetBreeding />
      </section>
    </>
  );
};

export default LandChestSale;
