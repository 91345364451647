import React from "react";
import { Row, Col } from "reactstrap";
import BirdImg from "../../assets/imgs/Bird31.png";
import Stamina from "../../assets/icons/stamina.png";
import Speed from "../../assets/icons/speed1.png";
import Strength from "../../assets/icons/strength.png";
import Morale from "../../assets/icons/health.png";
import Intellect from "../../assets/icons/accuracy.png";

const PetDetail = ({
  selectedPet,
  isBaseStats,
}: {
  selectedPet: any;
  isBaseStats: string;
}) => {
  return (
    <div className="petExchangeTopSec bg_remove">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="4">
            <div className="birdImg" id="smoth_scroll">
              <img
                src={selectedPet?.petImage ?? BirdImg}
                alt="birdImg"
                className="img-fluid"
              />
            </div>
          </Col>
          <Col lg="2">
            <div className="petExchangeTopSecData">
              {/* <h1 className="pageTitle text-left ">
                {selectedPet?.petName}
              </h1> */}

              <div className="customer_linking">
                <li>
                  {/* <label>Stamina :</label> */}
                  <img alt="" src={Stamina} width="22px" />
                  <strong>
                    {isBaseStats === "base"
                      ? selectedPet?.baseStats?.stamina ?? 0
                      : isBaseStats === "level"
                      ? selectedPet?.levelStats?.stamina ?? 0
                      : Number(selectedPet?.baseStats?.stamina ?? 0) +
                        Number(selectedPet?.levelStats?.stamina ?? 0)}
                  </strong>
                </li>
                <li>
                  {/* <label>Strength :</label> */}
                  <img alt="" src={Strength} width="25px" />
                  <strong>
                    {isBaseStats === "base"
                      ? selectedPet?.baseStats?.strength ?? 0
                      : isBaseStats === "level"
                      ? selectedPet?.levelStats?.strength ?? 0
                      : Number(selectedPet?.baseStats?.strength ?? 0) +
                        Number(selectedPet?.levelStats?.strength ?? 0)}
                  </strong>
                </li>
                <li>
                  {/* <label>Morale :</label> */}
                  <img alt="" src={Morale} width="25px" />
                  <strong>
                    {isBaseStats === "base"
                      ? selectedPet?.baseStats?.health ?? 0
                      : isBaseStats === "level"
                      ? selectedPet?.levelStats?.health ?? 0
                      : Number(selectedPet?.baseStats?.health ?? 0) +
                        Number(selectedPet?.levelStats?.health ?? 0)}
                  </strong>
                </li>
                <li>
                  {/* <label>Intellect :</label> */}
                  <img alt="" src={Intellect} width="25px" />
                  <strong>
                    {isBaseStats === "base"
                      ? selectedPet?.baseStats?.accuracy ?? 0
                      : isBaseStats === "level"
                      ? selectedPet?.levelStats?.accuracy ?? 0
                      : Number(selectedPet?.baseStats?.accuracy ?? 0) +
                        Number(selectedPet?.levelStats?.accuracy ?? 0)}
                  </strong>
                </li>
                <li>
                  {/* <label>Speed :</label> */}
                  <img alt="" src={Speed} width="25px" />
                  <strong>
                    {isBaseStats === "base"
                      ? selectedPet?.baseStats?.speed ?? 0
                      : isBaseStats === "level"
                      ? selectedPet?.levelStats?.speed ?? 0
                      : Number(selectedPet?.baseStats?.speed ?? 0) +
                        Number(selectedPet?.levelStats?.speed ?? 0)}
                  </strong>
                </li>
                <li>
                  <label className="LV1">LV</label>
                  <strong>
                    {isBaseStats === "base"
                      ? selectedPet?.baseStats?.level ?? 0
                      : isBaseStats === "level"
                      ? selectedPet?.levelStats?.level ?? 0
                      : Number(selectedPet?.baseStats?.level ?? 0) +
                        Number(selectedPet?.levelStats?.level ?? 0)}
                  </strong>
                </li>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PetDetail;
