import React from "react";
import { Route, Routes } from "react-router-dom";

interface Props {
  Component: any;
  path: string;
}
const AppRoutes = ({ Component, ...rest }: Props) => {
  return (
    <Routes>
      <Route {...rest} element={Component} />
    </Routes>
  );
};

export default AppRoutes;
