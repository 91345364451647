import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router";

const Privacypolicy = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div className="privacy_policy_main">
      <div className="container">
        <h5>Web Site Terms and Conditions of Use</h5>
        <p>
          <b className="mr-3">1.</b> By accessing this website, you are agreeing
          to be bound by this web site's Terms and Conditions of Use, applicable
          laws and regulations and their compliance. If you disagree with any of
          the stated terms and conditions, you are prohibited from using or
          accessing this site. The materials contained in this site are secured
          by relevant copyright and trade-mark law.
        </p>
        <p>
          <b className="mr-3">2.</b> Use License Permission is allowed to
          temporarily download one duplicate of the materials (data or
          programming) on Boba Mints's site for individual and non-business use
          only. This is the just a permit of license and not an exchange of
          title, and under this permit you may not: modify or copy the
          materials; use the materials for any commercial use, or for any public
          presentation (business or non-business); attempt to decompile or
          rebuild any product or material contained on Boba Mints's site; remove
          any copyright or other restrictive documentations from the materials;
          or transfer the materials to someone else or even "mirror" the
          materials on other servers. This permit might consequently be
          terminated if you disregard any of these confinements and may be ended
          by Boba Mints whenever deemed. After permit termination or when your
          viewing permit is terminated, you must destroy any downloaded
          materials in your ownership whether in electronic or printed form.
        </p>
        <p>
          <b className="mr-3">3.</b> Disclaimer: The materials on Boba Mints's
          site are given "as is". Boba Mints makes no guarantees, communicated
          or suggested, and thus renounces and nullifies every single other
          warranties, including without impediment, inferred guarantees or
          states of merchantability, fitness for a specific reason, or
          non-encroachment of licensed property or other infringement of rights.
          Further, Boba Mints does not warrant or make any representations
          concerning the precision, likely results, or unwavering quality of the
          utilization of the materials on its Internet site or generally
          identifying with such materials or on any destinations connected to
          this website.
        </p>
        <p>
          <b className="mr-3">4.</b> Constraints In no occasion should Boba
          Mints or its suppliers subject for any harms (counting, without
          constraint, harms for loss of information or benefit, or because of
          business interference,) emerging out of the utilization or
          powerlessness to utilize the materials on Boba Mints's Internet
          webpage, regardless of the possibility that Boba Mints or a Boba Mints
          approved agent has been told orally or in written of the likelihood of
          such harm. Since a few purviews don't permit constraints on inferred
          guarantees, or impediments of obligation for weighty or coincidental
          harms, these confinements may not make a difference to you.
        </p>
        <p>
          <b className="mr-3">5.</b> Amendments and Errata The materials showing
          up on Boba Mints's site could incorporate typographical, or
          photographic mistakes. Boba Mints does not warrant that any of the
          materials on its site are exact, finished, or current. Boba Mints may
          roll out improvements to the materials contained on its site whenever
          without notification. Boba Mints does not, then again, make any
          dedication to update the materials.
        </p>
        <p>
          <b className="mr-3">6.</b> Links Boba Mints has not checked on the
          majority of the websites or links connected to its website and is not
          in charge of the substance of any such connected webpage. The
          incorporation of any connection does not infer support by Boba Mints
          of the site. Utilization of any such connected site is at the user's
          own risk.
        </p>
        <p>
          <b className="mr-3">7.</b> Site Terms of Use Modifications Boba Mints
          may update these terms of utilization for its website whenever without
          notification. By utilizing this site you are consenting to be bound by
          the then current form of these Terms and Conditions of Use.
        </p>
        <p>
          <b className="mr-3">8.</b> Governing Law Any case identifying with
          Boba Mints's site should be administered by the laws of the country of
          Canada Boba Mints State without respect to its contention of law
          provisions. General Terms and Conditions applicable to Use of a Web
          Site.
        </p>
        <h5>Privacy Policy</h5>
        <p>
          Your privacy is critical to us. Likewise, we have built up this Policy
          with the end goal you should see how we gather, utilize, impart and
          reveal and make utilization of individual data. The following
          blueprints our privacy policy. Before or at the time of collecting
          personal information, we will identify the purposes for which
          information is being collected. We will gather and utilize individual
          data singularly with the target of satisfying those reasons indicated
          by us and for other good purposes, unless we get the assent of the
          individual concerned or as required by law. We will just hold
          individual data the length of essential for the satisfaction of those
          reasons. We will gather individual data by legal and reasonable means
          and, where fitting, with the information or assent of the individual
          concerned. Personal information ought to be important to the reasons
          for which it is to be utilized, and, to the degree essential for those
          reasons, ought to be exact, finished, and updated. We will protect
          individual data by security shields against misfortune or burglary,
          and also unapproved access, divulgence, duplicating, use or
          alteration. We will promptly provide customers with access to our
          policies and procedures for the administration of individual data. We
          are focused on leading our business as per these standards with a
          specific end goal to guarantee that the privacy of individual data is
          secure and maintained.
        </p>
      </div>
    </div>
  );
};

export default Privacypolicy;
