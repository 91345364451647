import React, { useEffect, useState } from "react";
import { Range } from "rc-slider";

interface IRangeSlider {
  label?: string;
  name: string;
  icon?: any;
  max: number;
  handleOnChangeFilter: (name: string, value: any) => void;
  value: number[];
}

const RangeSlider = ({
  icon,
  handleOnChangeFilter,
  name,
  label,
  max,
  value,
}: IRangeSlider) => {
  const [defaultValue, setDefaultValue] = useState([0, 100]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleOnChangeFilter(name, defaultValue);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <div className="rangeSlider">
      {label ? <span className="rangeLabel">{label}</span> : null}
      {icon ? <img className="icn" src={icon} alt="" /> : null}
      <div className="sliderInputWrap ml-1">
        <input
          min={0}
          type="number"
          // className="d-none"
          placeholder="min"
          value={defaultValue[0] > max ? 0 : defaultValue[0]}
          onChange={(event) => {
            const defaultValueCopy = defaultValue;
            defaultValueCopy[0] = Number(event.target.value);
            setDefaultValue([...defaultValueCopy]);
          }}
        />
        <Range
          min={0}
          max={max}
          value={[
            defaultValue[0] > max ? 0 : defaultValue[0],
            defaultValue[1] > max ? max : defaultValue[1],
          ]}
          defaultValue={defaultValue}
          onAfterChange={(range) => {
            handleOnChangeFilter(name, range);
          }}
          onChange={(value) => setDefaultValue([...value])}
        />
        <input
          min={0}
          type="number"
          placeholder="max"
          value={defaultValue[1] > max ? max : defaultValue[1]}
          onChange={(event) => {
            const defaultValueCopy = defaultValue;
            defaultValueCopy[1] = Number(event.target.value);
            setDefaultValue([...defaultValueCopy]);
          }}
        />
      </div>
    </div>
  );
};

export default RangeSlider;
