import { ethers } from "ethers";

export const getWhitelistData = async (
  projectAddress: string,
  address: string
) => {
  const json = require(`./../whitelist-data/${projectAddress}/${address}.json`);
  return json;
};

export const signMessage = async ({ setError, message }: any) => {
  try {
    if (!window.ethereum)
      throw new Error("No crypto wallet found. Please install it.");

    await window.ethereum.send("eth_requestAccounts");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const signature = await signer.signMessage(message);
    const address = await signer.getAddress();

    return {
      message,
      signature,
      address,
    };
  } catch (err: any) {
    setError(err.message);
  }
};
