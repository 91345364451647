import axios from "axios";
import config from "../config";

/**
 * Search App from api
 * @param {string} appName
 */

export const getPetExplorerApi = (data: any) => {
  return axios
    .post(`${config.apiBaseUrl}/WebApi/GetPetsWithinRange`, data)
    .then((response) => response)
    .catch((error) => error.response);
};
