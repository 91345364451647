import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Row, Col } from "reactstrap";
import { Filter } from "../Filter";
import { PetList } from "../PetList";
import { connect } from "react-redux";
import { getPetExplorerAction } from "../../redux/actions/petExplorer";
import { IPetExplorerInterFace } from "../../interfaces/Common";
import PetDetail from "./PetDetail";
import { IFilterProps } from "../../interfaces/Filter";
import decordheader from "../../assets/imgs/decordheader1.png";
import ReactPaginate from "react-paginate";
import { CommonContext } from "../../contexts/CommonContext";
import CardLoader from "../../components/loaders/CardLoader";

interface IPetExplorer {
  petExplorer: IPetExplorerInterFace;
  getPetExplorerAction: (data: any) => void;
}

const baseStatsMaxValue = 110;
const PetExplorer: FunctionComponent<IPetExplorer> = ({
  petExplorer,
  getPetExplorerAction,
}): ReactElement => {
  const { theme } = useContext(CommonContext);
  const [page, setPage] = useState<number>(1);
  const [limit] = useState<number>(12);
  const [selectedPet, setSelectedPet] = useState<any>();
  const [isOpenDetail, setIsOpenDetail] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<IFilterProps>({
    intellectRange: [0, 110],
    breedCount: false,
    levelRange: [0, 110],
    moraleRange: [0, 110],
    petTypes: [],
    speedRange: [0, 110],
    staminaRange: [0, 110],
    strengthRange: [0, 110],
    filter: "base",
    isAdult: false,
  });

  const makeRequest = (filterOptions: IFilterProps) => {
    const params: any = {
      limit,
    };

    // Intellect Start
    if (filterOptions.intellectRange[0] !== 0) {
      params.minIntellect =
        filterOptions.filter !== "base"
          ? filterOptions.intellectRange[0]
          : filterOptions.intellectRange[0] > baseStatsMaxValue
          ? 0
          : filterOptions.intellectRange[0];
    }
    if (filterOptions.intellectRange[1] !== 0) {
      params.maxIntellect =
        filterOptions.filter !== "base"
          ? filterOptions.intellectRange[1]
          : filterOptions.intellectRange[1] > baseStatsMaxValue
          ? baseStatsMaxValue
          : filterOptions.intellectRange[1];
    }
    // Intellect End
    // Level Start
    if (filterOptions.levelRange[0] !== 0) {
      params.minLevel = filterOptions.levelRange[0];
    }
    if (filterOptions.levelRange[1] !== 0) {
      params.maxLevel = filterOptions.levelRange[1];
    }
    // Level End
    // Morale Start
    if (filterOptions.moraleRange[0] !== 0) {
      params.minMorale =
        filterOptions.filter !== "base"
          ? filterOptions.moraleRange[0]
          : filterOptions.moraleRange[0] > baseStatsMaxValue
          ? 0
          : filterOptions.moraleRange[0];
    }
    if (filterOptions.moraleRange[1] !== 0) {
      params.maxMorale =
        filterOptions.filter !== "base"
          ? filterOptions.moraleRange[1]
          : filterOptions.moraleRange[1] > baseStatsMaxValue
          ? baseStatsMaxValue
          : filterOptions.moraleRange[1];
    }
    // Morale End
    // Speed Start
    if (filterOptions.speedRange[0] !== 0) {
      params.minSpeed =
        filterOptions.filter !== "base"
          ? filterOptions.speedRange[0]
          : filterOptions.speedRange[0] > baseStatsMaxValue
          ? 0
          : filterOptions.speedRange[0];
    }
    if (filterOptions.speedRange[1] !== 0) {
      params.maxSpeed =
        filterOptions.filter !== "base"
          ? filterOptions.speedRange[1]
          : filterOptions.speedRange[1] > baseStatsMaxValue
          ? baseStatsMaxValue
          : filterOptions.speedRange[1];
    }
    // Speed End
    // Stamina Start
    if (filterOptions.staminaRange[0] !== 0) {
      params.minStamina =
        filterOptions.filter !== "base"
          ? filterOptions.staminaRange[0]
          : filterOptions.staminaRange[0] > baseStatsMaxValue
          ? 0
          : filterOptions.staminaRange[0];
    }
    if (filterOptions.staminaRange[1] !== 0) {
      params.maxStamina =
        filterOptions.filter !== "base"
          ? filterOptions.staminaRange[1]
          : filterOptions.staminaRange[1] > baseStatsMaxValue
          ? baseStatsMaxValue
          : filterOptions.staminaRange[1];
    }
    // Stamina End
    // Strength Start
    if (filterOptions.strengthRange[0] !== 0) {
      params.minStrength =
        filterOptions.filter !== "base"
          ? filterOptions.strengthRange[0]
          : filterOptions.strengthRange[0] > baseStatsMaxValue
          ? 0
          : filterOptions.strengthRange[0];
    }
    if (filterOptions.strengthRange[1] !== 0) {
      params.maxStrength =
        filterOptions.filter !== "base"
          ? filterOptions.strengthRange[1]
          : filterOptions.strengthRange[1] > baseStatsMaxValue
          ? baseStatsMaxValue
          : filterOptions.strengthRange[1];
    }
    // Strength End
    if (filterOptions.breedCount) {
      params.breedCount = filterOptions.breedCount ? 1 : 0;
    }
    if (filterOptions.isAdult) {
      params.isAdult = filterOptions.isAdult ? 1 : 0;
    }
    if (filterOptions.petTypes.length > 0) {
      params.petTypes = filterOptions.petTypes;
    }
    params.filter = filterOptions.filter;
    return params;
  };

  /**
   * Get Pets List Data
   */
  const getPetExplorerData = useCallback(() => {
    const params = makeRequest(filterParams);
    params.pageNumber = page;
    if (theme._id !== "") {
      params.kingdomId = theme._id;
    }
    getPetExplorerAction(params);
    setIsOpenDetail(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams, getPetExplorerAction, page, theme]);

  /**
   * Display Pet detail on the top of the list
   * @param pet single pet detail
   */
  const handleSelectPetDetail = (pet: any) => {
    setSelectedPet(pet);
    if (selectedPet?._id === pet._id) {
      setIsOpenDetail(!isOpenDetail);
      setSelectedPet(null);
    } else {
      setIsOpenDetail(true);
    }
  };
  const handleOnChangeFilter = (name: string, value: any) => {
    setPage(1);
    setFilterParams((values: IFilterProps) => ({
      ...values,
      [name]: value,
    }));
  };
  const handlePageClick = (pageNumber: { selected: number }) => {
    setPage(pageNumber.selected + 1);
  };
  useEffect(() => {
    getPetExplorerData();
  }, [getPetExplorerData, filterParams, page, theme]);
  const { data, loader } = petExplorer;
  return (
    <>
      <div className="filter_bg">
        <h3 className="promo__title_decorative text-center">Jea Explorer</h3>
      </div>
      <section className="petExplorerPage innerPageTopSpace bg_yellow pt">
        {isOpenDetail ? (
          <PetDetail
            isBaseStats={filterParams.filter}
            selectedPet={selectedPet}
          />
        ) : null}
        <div
          className={`mb-5 petExchangeBtmSec ${!isOpenDetail ? "mt-5" : ""}`}
        >
          <div className="container">
            <Row>
              <Filter
                handleOnChangeFilter={handleOnChangeFilter}
                filterParams={filterParams}
                isBaseStats={filterParams.filter}
              />
              <Col lg="8" md="8">
                {loader ? (
                  <CardLoader count={6} />
                ) : (
                  <>
                    {data?.petsInfo && data?.petsInfo.length > 0 ? (
                      <PetList
                        selectedId={selectedPet?._id ?? ""}
                        onSelectItem={handleSelectPetDetail}
                        petsList={data?.petsInfo ?? []}
                        totalPages={data?.totalPages ?? 0}
                        isBaseStats={filterParams.filter}
                      />
                    ) : (
                      <div className="no-data-found d-flex justify-content-center align-items-center h-100">
                        <h4>No data found</h4>
                      </div>
                    )}
                  </>
                )}
                {data?.totalPages && data?.totalPages > 1 ? (
                  <div className="birdsPagination my-5">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={data?.totalPages ?? 1}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      forcePage={page - 1}
                    />
                  </div>
                ) : null}
              </Col>
            </Row>
          </div>
        </div>
        <div className="bg_parpul sectionfourth">
          <div className="container">
            <div className="row text-center justify-content-center">
              <div className="col-lg-3">
                <p>
                  Development<br></br> Updates!
                </p>
              </div>
              <div className="col-lg-3">
                <p>
                  Join Our<br></br>
                  Discord!
                </p>
              </div>
              <div className="col-lg-3">
                <p>
                  NFT Giveaway<br></br> Contests!
                </p>
              </div>
            </div>
            <div className="text-center pt-5">
              <a
                href="https://tanjea.gitbook.io/untitled/ "
                target="_blank"
                className="btn yellowBtn"
                rel="noreferrer"
              >
                <img src={decordheader} className="mr-3" alt="" />
                Discord
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const mapStateToProps = ({
  petExplorer,
}: {
  petExplorer: IPetExplorerInterFace;
}) => ({
  petExplorer,
});
const mapDispatcherToProps = {
  getPetExplorerAction,
};
export default connect(mapStateToProps, mapDispatcherToProps)(PetExplorer);
