import React from "react";
import { Row, Col, Form, Input, Button, FormGroup } from "reactstrap";
import { Link } from "react-router-dom";
import Logo from "../../assets/imgs/Header-Logo-Banner.png";

const Signup = () => {
  // Fade Open state
  return (
    <section className="loginWrapper">
      <div className="container">
        <Row className="justify-content-between align-items-center">
          <Col md="6" className="d-none d-md-block">
            <div className="loginDataSec">
              <h1>Signup</h1>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.{" "}
              </p>
              <Link to="/" className="yellowBtn">
                Back to home
              </Link>
            </div>
          </Col>
          <Col md="6" lg="5">
            <div className="loginInner">
              <div className="text-center">
                <img src={Logo} alt="Logo" className="loginScreenLogo" />
              </div>
              <Form>
                <FormGroup>
                  <Input
                    id=""
                    className="username"
                    name=""
                    placeholder="User name"
                    type="text"
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    id=""
                    className="email"
                    name=""
                    placeholder="Email address"
                    type="email"
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    id=""
                    className="password"
                    name=""
                    placeholder="Password"
                    type="password"
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    id=""
                    className="password"
                    name=""
                    placeholder="Confirm password"
                    type="password"
                  />
                </FormGroup>
                <div className="btnGroup">
                  <Button className="yellowBtn">Signup</Button>
                </div>
                <div className="signupLink">
                  Already have an account. <Link to="/login">Login</Link>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Signup;
