import React from "react";
import decordheader from "../../assets/imgs/whitediscord.png";
import config from "../../config";

const Checkout = () => {
  return (
    <div>
      <h5 className="promo__title promo__title_decorative">
        Trailer
      </h5>
      <div className="container">
        <div className="video_frame">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/R1X5gP4jYlw"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <h5 className="promo__title promo__title_decorative join_discord mt-5">
        Join Our Discord!
      </h5>
      <div className="text-center pt-3">
        <a
          href={config.discordLink}
          target="_blank"
          className="btn yellowBtn bgchange"
          rel="noreferrer"
        >
          <img src={decordheader} className="mr-3" width="20%" alt="" />
          Discord
        </a>
      </div>
    </div>
  );
};

export default Checkout;
