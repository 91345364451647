import { FC } from "react";
import { Row, Col } from "reactstrap";
import GoldCoin from "../../../assets/imgs/gold-coin.svg";
import EtheruemLogo from "../../../assets/imgs/etheruem-logo.svg";
import MarkAteer from "../../../assets/imgs/markAteer.png";

const MyAccountInfo: FC = () => {
  return (
    <div className="myAccountInfo">
      <div className="container">
        <h1 className="pageTitle">my account</h1>
        <Row className="justify-content-center align-items-center">
          <Col md="3" sm="4" className="text-center order-sm-2">
            <div className="myAccountPic_info">
              <img src={MarkAteer} alt="MarkAteer" className="img-fluid" />
              <h4>Mark Ateer</h4>
            </div>
          </Col>
          <Col md="3" sm="4" xs={6} className="text-center order-sm-1">
            <div className="myAccountLR_info">
              <label>Deposit to game</label>
              <img src={GoldCoin} alt="GoldCoin" className="img-fluid" />
              <strong>100</strong>
            </div>
          </Col>
          <Col md="3" sm="4" xs={6} className="text-center order-sm-3">
            <div className="myAccountLR_info">
              <label>Swap gold and withdraw TNJ</label>
              <img
                src={EtheruemLogo}
                alt="EtheruemLogo"
                className="img-fluid"
              />
              <strong>150</strong>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MyAccountInfo;
