const initialState = {
  loader: true,
  data: null,
  message: "",
};
/* eslint-disable import/no-anonymous-default-export */
export default (state = initialState, action: any) => {
  switch (action.type) {
    case "ACTION_START":
      return {
        ...state,
        loader: true,
      };
    case "FETCHED_PETS":
      return {
        ...state,
        data: action.payload ?? null,
      };
    case "ERROR":
      return {
        ...state,
        loader: false,
      };
    case "COMPLETE_ACTION":
      return {
        ...state,
        loader: false,
      };
    default:
      return state;
  }
};
