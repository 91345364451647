import HomeBanner from "../../components/HomeComponents/HomeBanner";
import Greeting from "../../components/HomeComponents/Greeting";
import Capturing from "../../components/HomeComponents/Capturing";
import CollectRare from "../../components/HomeComponents/CollectRare";
import Promo from "../../components/HomeComponents/Promo";
import PoweringPet from "../../components/HomeComponents/PoweringPet";
import PetBreeding from "../../components/HomeComponents/PetBreeding";
import { Helmet } from "react-helmet";
import Roadmap from "../../components/HomeComponents/Roadmap";
import Founders from "../../components/HomeComponents/Founders";

const Home = () => {
  return (
    <>
      <Helmet>
        <script>
          {`!(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = "2.0";
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    "script",
    "https://connect.facebook.net/en_US/fbevents.js"
  );
  fbq("init", "1002681580350252");
  fbq("track", "PageView");`}
        </script>
      </Helmet>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=1002681580350252&ev=PageView&noscript=1"
          alt=""
        />
      </noscript>
      <HomeBanner />
      <Greeting />
      <Capturing />
      <CollectRare />
      <Promo />
      <PoweringPet />
      <Roadmap />
      <Founders />
      <PetBreeding />
    </>
  );
};

export default Home;
