import { handleResponse } from "../../config/common";
import { getPetExplorerApi } from "../../services/PetExplorerServices";

export const getPetExplorerAction = (payload: any) => async (dispatch: any) => {
  dispatch({ type: "ACTION_START" });
  const res = await getPetExplorerApi(payload);
  const response = await handleResponse(res);
  if (response.success) {
    dispatch({
      type: "FETCHED_PETS",
      payload: response.data?.AllPetData ?? [],
    });
  } else {
    dispatch({
      type: "ERROR",
      payload: response.message,
    });
  }
  dispatch({ type: "COMPLETE_ACTION" });
};
