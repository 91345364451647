export const contractAddressFactory =
  "0x1d1976543d03a083911483Ea57B600EEF5ac8F02";
export const abiFactory = [
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"kingdomIdx",
            "type":"uint256"
         },
         {
            "indexed":true,
            "internalType":"bytes32",
            "name":"kingdomHash",
            "type":"bytes32"
         }
      ],
      "name":"LogKingdomCreated",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"minter",
            "type":"address"
         }
      ],
      "name":"LogMinterCreated",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"project",
            "type":"address"
         }
      ],
      "name":"LogProjectCreated",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
         },
         {
            "indexed":true,
            "internalType":"bytes32",
            "name":"previousAdminRole",
            "type":"bytes32"
         },
         {
            "indexed":true,
            "internalType":"bytes32",
            "name":"newAdminRole",
            "type":"bytes32"
         }
      ],
      "name":"RoleAdminChanged",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"account",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"sender",
            "type":"address"
         }
      ],
      "name":"RoleGranted",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"account",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"sender",
            "type":"address"
         }
      ],
      "name":"RoleRevoked",
      "type":"event"
   },
   {
      "inputs":[
         
      ],
      "name":"DEFAULT_ADMIN_ROLE",
      "outputs":[
         {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"admin",
            "type":"address"
         }
      ],
      "name":"addProjectAdmin",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "components":[
               {
                  "internalType":"string",
                  "name":"name",
                  "type":"string"
               },
               {
                  "internalType":"string",
                  "name":"kingdomImage",
                  "type":"string"
               },
               {
                  "internalType":"string",
                  "name":"description",
                  "type":"string"
               },
               {
                  "internalType":"bytes32",
                  "name":"kingdomHash",
                  "type":"bytes32"
               }
            ],
            "internalType":"struct Registry.KingdomDetail",
            "name":"kingdom",
            "type":"tuple"
         }
      ],
      "name":"createKingdom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"minterImplementation",
            "type":"address"
         },
         {
            "internalType":"address[]",
            "name":"payees",
            "type":"address[]"
         },
         {
            "internalType":"uint256[]",
            "name":"shares",
            "type":"uint256[]"
         }
      ],
      "name":"createMinter",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "components":[
               {
                  "internalType":"enum Registry.ProjectType",
                  "name":"projectType",
                  "type":"uint8"
               },
               {
                  "internalType":"uint128",
                  "name":"kingdomIdx",
                  "type":"uint128"
               },
               {
                  "internalType":"string",
                  "name":"name",
                  "type":"string"
               },
               {
                  "internalType":"string",
                  "name":"symbol",
                  "type":"string"
               },
               {
                  "internalType":"string",
                  "name":"description",
                  "type":"string"
               },
               {
                  "internalType":"string",
                  "name":"license",
                  "type":"string"
               }
            ],
            "internalType":"struct Registry.ProjectDetail",
            "name":"projectDetail",
            "type":"tuple"
         },
         {
            "components":[
               {
                  "internalType":"uint256",
                  "name":"maxSupply",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"maxBlockPurchase",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"maxWalletPurchase",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"price",
                  "type":"uint256"
               },
               {
                  "internalType":"address",
                  "name":"minter",
                  "type":"address"
               },
               {
                  "internalType":"address",
                  "name":"baseTokenImplementation",
                  "type":"address"
               },
               {
                  "internalType":"address",
                  "name":"staking",
                  "type":"address"
               },
               {
                  "internalType":"bool",
                  "name":"isFreeMint",
                  "type":"bool"
               },
               {
                  "internalType":"enum Registry.ProjectStatus",
                  "name":"status",
                  "type":"uint8"
               }
            ],
            "internalType":"struct Registry.ProjectMint",
            "name":"projectMint",
            "type":"tuple"
         },
         {
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "internalType":"bytes32",
            "name":"kingdomHash",
            "type":"bytes32"
         }
      ],
      "name":"createProject",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"kingdomIdx",
            "type":"uint256"
         }
      ],
      "name":"getKingdom",
      "outputs":[
         {
            "components":[
               {
                  "internalType":"string",
                  "name":"name",
                  "type":"string"
               },
               {
                  "internalType":"string",
                  "name":"kingdomImage",
                  "type":"string"
               },
               {
                  "internalType":"string",
                  "name":"description",
                  "type":"string"
               },
               {
                  "internalType":"bytes32",
                  "name":"kingdomHash",
                  "type":"bytes32"
               }
            ],
            "internalType":"struct Registry.KingdomDetail",
            "name":"",
            "type":"tuple"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"getNumbProjects",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         }
      ],
      "name":"getProject",
      "outputs":[
         {
            "components":[
               {
                  "components":[
                     {
                        "internalType":"enum Registry.ProjectType",
                        "name":"projectType",
                        "type":"uint8"
                     },
                     {
                        "internalType":"uint128",
                        "name":"kingdomIdx",
                        "type":"uint128"
                     },
                     {
                        "internalType":"string",
                        "name":"name",
                        "type":"string"
                     },
                     {
                        "internalType":"string",
                        "name":"symbol",
                        "type":"string"
                     },
                     {
                        "internalType":"string",
                        "name":"description",
                        "type":"string"
                     },
                     {
                        "internalType":"string",
                        "name":"license",
                        "type":"string"
                     }
                  ],
                  "internalType":"struct Registry.ProjectDetail",
                  "name":"detail",
                  "type":"tuple"
               },
               {
                  "components":[
                     {
                        "internalType":"uint256",
                        "name":"maxSupply",
                        "type":"uint256"
                     },
                     {
                        "internalType":"uint256",
                        "name":"maxBlockPurchase",
                        "type":"uint256"
                     },
                     {
                        "internalType":"uint256",
                        "name":"maxWalletPurchase",
                        "type":"uint256"
                     },
                     {
                        "internalType":"uint256",
                        "name":"price",
                        "type":"uint256"
                     },
                     {
                        "internalType":"address",
                        "name":"minter",
                        "type":"address"
                     },
                     {
                        "internalType":"address",
                        "name":"baseTokenImplementation",
                        "type":"address"
                     },
                     {
                        "internalType":"address",
                        "name":"staking",
                        "type":"address"
                     },
                     {
                        "internalType":"bool",
                        "name":"isFreeMint",
                        "type":"bool"
                     },
                     {
                        "internalType":"enum Registry.ProjectStatus",
                        "name":"status",
                        "type":"uint8"
                     }
                  ],
                  "internalType":"struct Registry.ProjectMint",
                  "name":"minting",
                  "type":"tuple"
               }
            ],
            "internalType":"struct Registry.Project",
            "name":"",
            "type":"tuple"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         }
      ],
      "name":"getProjectAdminRole",
      "outputs":[
         {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
         }
      ],
      "stateMutability":"pure",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         }
      ],
      "name":"getProjectDetail",
      "outputs":[
         {
            "components":[
               {
                  "internalType":"enum Registry.ProjectType",
                  "name":"projectType",
                  "type":"uint8"
               },
               {
                  "internalType":"uint128",
                  "name":"kingdomIdx",
                  "type":"uint128"
               },
               {
                  "internalType":"string",
                  "name":"name",
                  "type":"string"
               },
               {
                  "internalType":"string",
                  "name":"symbol",
                  "type":"string"
               },
               {
                  "internalType":"string",
                  "name":"description",
                  "type":"string"
               },
               {
                  "internalType":"string",
                  "name":"license",
                  "type":"string"
               }
            ],
            "internalType":"struct Registry.ProjectDetail",
            "name":"",
            "type":"tuple"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         }
      ],
      "name":"getProjectFreeStatus",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         }
      ],
      "name":"getProjectLicense",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         }
      ],
      "name":"getProjectMaxBlockPurchase",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         }
      ],
      "name":"getProjectMaxSupply",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         }
      ],
      "name":"getProjectMaxWalletPurchase",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         }
      ],
      "name":"getProjectMinter",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         }
      ],
      "name":"getProjectMinting",
      "outputs":[
         {
            "components":[
               {
                  "internalType":"uint256",
                  "name":"maxSupply",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"maxBlockPurchase",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"maxWalletPurchase",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"price",
                  "type":"uint256"
               },
               {
                  "internalType":"address",
                  "name":"minter",
                  "type":"address"
               },
               {
                  "internalType":"address",
                  "name":"baseTokenImplementation",
                  "type":"address"
               },
               {
                  "internalType":"address",
                  "name":"staking",
                  "type":"address"
               },
               {
                  "internalType":"bool",
                  "name":"isFreeMint",
                  "type":"bool"
               },
               {
                  "internalType":"enum Registry.ProjectStatus",
                  "name":"status",
                  "type":"uint8"
               }
            ],
            "internalType":"struct Registry.ProjectMint",
            "name":"",
            "type":"tuple"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         }
      ],
      "name":"getProjectPrice",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         }
      ],
      "name":"getProjectStatus",
      "outputs":[
         {
            "internalType":"enum Registry.ProjectStatus",
            "name":"",
            "type":"uint8"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
         }
      ],
      "name":"getRoleAdmin",
      "outputs":[
         {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
         },
         {
            "internalType":"uint256",
            "name":"index",
            "type":"uint256"
         }
      ],
      "name":"getRoleMember",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
         }
      ],
      "name":"getRoleMemberCount",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
         },
         {
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"grantRole",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
         },
         {
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"hasRole",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"initialize",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"kingdoms",
      "outputs":[
         {
            "internalType":"string",
            "name":"name",
            "type":"string"
         },
         {
            "internalType":"string",
            "name":"kingdomImage",
            "type":"string"
         },
         {
            "internalType":"string",
            "name":"description",
            "type":"string"
         },
         {
            "internalType":"bytes32",
            "name":"kingdomHash",
            "type":"bytes32"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"projectIdToAddress",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "name":"projects",
      "outputs":[
         {
            "components":[
               {
                  "internalType":"enum Registry.ProjectType",
                  "name":"projectType",
                  "type":"uint8"
               },
               {
                  "internalType":"uint128",
                  "name":"kingdomIdx",
                  "type":"uint128"
               },
               {
                  "internalType":"string",
                  "name":"name",
                  "type":"string"
               },
               {
                  "internalType":"string",
                  "name":"symbol",
                  "type":"string"
               },
               {
                  "internalType":"string",
                  "name":"description",
                  "type":"string"
               },
               {
                  "internalType":"string",
                  "name":"license",
                  "type":"string"
               }
            ],
            "internalType":"struct Registry.ProjectDetail",
            "name":"detail",
            "type":"tuple"
         },
         {
            "components":[
               {
                  "internalType":"uint256",
                  "name":"maxSupply",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"maxBlockPurchase",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"maxWalletPurchase",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"price",
                  "type":"uint256"
               },
               {
                  "internalType":"address",
                  "name":"minter",
                  "type":"address"
               },
               {
                  "internalType":"address",
                  "name":"baseTokenImplementation",
                  "type":"address"
               },
               {
                  "internalType":"address",
                  "name":"staking",
                  "type":"address"
               },
               {
                  "internalType":"bool",
                  "name":"isFreeMint",
                  "type":"bool"
               },
               {
                  "internalType":"enum Registry.ProjectStatus",
                  "name":"status",
                  "type":"uint8"
               }
            ],
            "internalType":"struct Registry.ProjectMint",
            "name":"minting",
            "type":"tuple"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
         },
         {
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"renounceRole",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
         },
         {
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"revokeRole",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         },
         {
            "internalType":"string",
            "name":"baseURI_",
            "type":"string"
         }
      ],
      "name":"setBaseURI",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"supply",
            "type":"uint256"
         }
      ],
      "name":"setMaxSupply",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "internalType":"string",
            "name":"newTokenURI",
            "type":"string"
         }
      ],
      "name":"setTokenURI",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes4",
            "name":"interfaceId",
            "type":"bytes4"
         }
      ],
      "name":"supportsInterface",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         },
         {
            "components":[
               {
                  "internalType":"enum Registry.ProjectType",
                  "name":"projectType",
                  "type":"uint8"
               },
               {
                  "internalType":"uint128",
                  "name":"kingdomIdx",
                  "type":"uint128"
               },
               {
                  "internalType":"string",
                  "name":"name",
                  "type":"string"
               },
               {
                  "internalType":"string",
                  "name":"symbol",
                  "type":"string"
               },
               {
                  "internalType":"string",
                  "name":"description",
                  "type":"string"
               },
               {
                  "internalType":"string",
                  "name":"license",
                  "type":"string"
               }
            ],
            "internalType":"struct Registry.ProjectDetail",
            "name":"info",
            "type":"tuple"
         }
      ],
      "name":"updateProjectDetail",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         },
         {
            "internalType":"bool",
            "name":"isFree",
            "type":"bool"
         }
      ],
      "name":"updateProjectFreeStatus",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         },
         {
            "components":[
               {
                  "components":[
                     {
                        "internalType":"enum Registry.ProjectType",
                        "name":"projectType",
                        "type":"uint8"
                     },
                     {
                        "internalType":"uint128",
                        "name":"kingdomIdx",
                        "type":"uint128"
                     },
                     {
                        "internalType":"string",
                        "name":"name",
                        "type":"string"
                     },
                     {
                        "internalType":"string",
                        "name":"symbol",
                        "type":"string"
                     },
                     {
                        "internalType":"string",
                        "name":"description",
                        "type":"string"
                     },
                     {
                        "internalType":"string",
                        "name":"license",
                        "type":"string"
                     }
                  ],
                  "internalType":"struct Registry.ProjectDetail",
                  "name":"detail",
                  "type":"tuple"
               },
               {
                  "components":[
                     {
                        "internalType":"uint256",
                        "name":"maxSupply",
                        "type":"uint256"
                     },
                     {
                        "internalType":"uint256",
                        "name":"maxBlockPurchase",
                        "type":"uint256"
                     },
                     {
                        "internalType":"uint256",
                        "name":"maxWalletPurchase",
                        "type":"uint256"
                     },
                     {
                        "internalType":"uint256",
                        "name":"price",
                        "type":"uint256"
                     },
                     {
                        "internalType":"address",
                        "name":"minter",
                        "type":"address"
                     },
                     {
                        "internalType":"address",
                        "name":"baseTokenImplementation",
                        "type":"address"
                     },
                     {
                        "internalType":"address",
                        "name":"staking",
                        "type":"address"
                     },
                     {
                        "internalType":"bool",
                        "name":"isFreeMint",
                        "type":"bool"
                     },
                     {
                        "internalType":"enum Registry.ProjectStatus",
                        "name":"status",
                        "type":"uint8"
                     }
                  ],
                  "internalType":"struct Registry.ProjectMint",
                  "name":"minting",
                  "type":"tuple"
               }
            ],
            "internalType":"struct Registry.Project",
            "name":"info",
            "type":"tuple"
         }
      ],
      "name":"updateProjectInfo",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"maxPurchase",
            "type":"uint256"
         }
      ],
      "name":"updateProjectMaxBlockPurchase",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"maxPurchase",
            "type":"uint256"
         }
      ],
      "name":"updateProjectMaxWalletPurchase",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         },
         {
            "components":[
               {
                  "internalType":"uint256",
                  "name":"maxSupply",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"maxBlockPurchase",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"maxWalletPurchase",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"price",
                  "type":"uint256"
               },
               {
                  "internalType":"address",
                  "name":"minter",
                  "type":"address"
               },
               {
                  "internalType":"address",
                  "name":"baseTokenImplementation",
                  "type":"address"
               },
               {
                  "internalType":"address",
                  "name":"staking",
                  "type":"address"
               },
               {
                  "internalType":"bool",
                  "name":"isFreeMint",
                  "type":"bool"
               },
               {
                  "internalType":"enum Registry.ProjectStatus",
                  "name":"status",
                  "type":"uint8"
               }
            ],
            "internalType":"struct Registry.ProjectMint",
            "name":"info",
            "type":"tuple"
         }
      ],
      "name":"updateProjectMinting",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"price",
            "type":"uint256"
         }
      ],
      "name":"updateProjectPrice",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"project",
            "type":"address"
         },
         {
            "internalType":"enum Registry.ProjectStatus",
            "name":"status",
            "type":"uint8"
         }
      ],
      "name":"updateProjectStatus",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   }
];
