import React from "react";
import { Row, Col } from "reactstrap";
import Skeleton from "react-loading-skeleton";

const CardLoader = ({ count }: { count: number }) => {
  return (
    <Row>
      {[...Array(count)].map((item, i) => (
        <Col sm="6" xl="4" key={i}>
          <div className="birdsCard">
            <div className="border_hover">
              <div className="birdsImgSec">
                <Skeleton height={220} />
              </div>
              {/* <div className="birdsName">{petDetail.petName}</div> */}
              <ul className="d-flex birdsallCards">
                <li className="mr-1">
                  <div className="d-flex  align-items-center">
                    <Skeleton circle={true} width={15} height={15} />
                    <Skeleton width={30} className="ml-2 mt-2" height={10} />
                  </div>
                  <div className="d-flex  align-items-center">
                    <Skeleton circle={true} width={15} height={15} />
                    <Skeleton width={30} className="ml-2 mt-2" height={10} />
                  </div>
                </li>
                <li className="mr-1">
                  <div className="d-flex  align-items-center">
                    <Skeleton circle={true} width={15} height={15} />
                    <Skeleton width={30} className="ml-2 mt-2" height={10} />
                  </div>
                  <div className="d-flex  align-items-center">
                    <Skeleton circle={true} width={15} height={15} />
                    <Skeleton width={30} className="ml-2 mt-2" height={10} />
                  </div>
                </li>
                <li className="mr-1">
                  <div className="d-flex  align-items-center">
                    <Skeleton circle={true} width={15} height={15} />
                    <Skeleton width={30} className="ml-2 mt-2" height={10} />
                  </div>
                  <div className="d-flex  align-items-center">
                    <Skeleton circle={true} width={15} height={15} />
                    <Skeleton width={30} className="ml-2 mt-2" height={10} />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default CardLoader;
