/* eslint-disable jsx-a11y/alt-text */
import { Row, Col, Progress, Table } from "reactstrap";

const MyScores = () => {
  return (
    <section className="myAccountPage my-4">
      {/* <MyAccountInfo /> */}
      {/* <div className="container">
        <div className="myaccountNav">
          <ul>
            <li>
              <Link className="nav-link" to="/my-account/inventory">
                Inventory
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/my-account/breed">
                Breed
              </Link>
            </li>
            <li>
              <Link className="nav-link active" to="/my-account/my-scores">
                My Scores
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/my-account/listed-pets">
                Listed Pets
              </Link>
            </li>
          </ul>
        </div>
      </div> */}

      <div className="">
        <div className="container">
          <Row>
            <Col md="12">
              <div className="petListTbl">
                <Table borderless hover responsive>
                  <thead>
                    <tr>
                      <th>play</th>
                      <th>game name</th>
                      <th>game levels</th>
                      <th>your score</th>
                      <th>high score</th>
                      <th>your rank</th>
                      <th>expected earnings</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td className="gameNameCol">
                        <img
                          src={require("../../assets/imgs/player1.png").default}
                          className="playerImg"
                        />
                        <div className="gameName">
                          <label>Gold Flight</label>
                          <Progress value={50} />
                        </div>
                      </td>
                      <td>
                        <div className="gameLabel">
                          <span>1</span>
                          <span>2</span>
                          <span>
                            <img
                              src={
                                require("../../assets/imgs/markAteer.png")
                                  .default
                              }
                            />
                          </span>
                          <span>4</span>
                        </div>
                      </td>
                      <td>90</td>
                      <td>105</td>
                      <td>1</td>
                      <td>147</td>
                    </tr>
                    <tr>
                      <th scope="row">1</th>
                      <td className="gameNameCol">
                        <img
                          src={require("../../assets/imgs/player1.png").default}
                          className="playerImg"
                        />
                        <div className="gameName">
                          <label>Rocky Crush</label>
                          <Progress value={50} />
                        </div>
                      </td>
                      <td>
                        <div className="gameLabel">
                          <span>
                            <img
                              src={
                                require("../../assets/imgs/markAteer.png")
                                  .default
                              }
                            />
                          </span>
                          <span>2</span>
                          <span>3</span>
                          <span>4</span>
                        </div>
                      </td>
                      <td>45</td>
                      <td>99</td>
                      <td>6</td>
                      <td>160</td>
                    </tr>
                    <tr>
                      <th scope="row">1</th>
                      <td className="gameNameCol">
                        <img
                          src={require("../../assets/imgs/player1.png").default}
                          className="playerImg"
                        />
                        <div className="gameName">
                          <label>Gold Flight</label>
                          <Progress value={50} />
                        </div>
                      </td>
                      <td>
                        <div className="gameLabel">
                          <span>1</span>
                          <span>2</span>
                          <span>
                            <img
                              src={
                                require("../../assets/imgs/markAteer.png")
                                  .default
                              }
                            />
                          </span>
                          <span>4</span>
                        </div>
                      </td>
                      <td>90</td>
                      <td>105</td>
                      <td>1</td>
                      <td>147</td>
                    </tr>
                    <tr>
                      <th scope="row">1</th>
                      <td className="gameNameCol">
                        <img
                          src={require("../../assets/imgs/player1.png").default}
                          className="playerImg"
                        />
                        <div className="gameName">
                          <label>Rocky Crush</label>
                          <Progress value={50} />
                        </div>
                      </td>
                      <td>
                        <div className="gameLabel">
                          <span>
                            <img
                              src={
                                require("../../assets/imgs/markAteer.png")
                                  .default
                              }
                            />
                          </span>
                          <span>2</span>
                          <span>3</span>
                          <span>4</span>
                        </div>
                      </td>
                      <td>45</td>
                      <td>99</td>
                      <td>6</td>
                      <td>160</td>
                    </tr>
                    <tr>
                      <th scope="row">1</th>
                      <td className="gameNameCol">
                        <img
                          src={require("../../assets/imgs/player1.png").default}
                          className="playerImg"
                        />
                        <div className="gameName">
                          <label>Gold Flight</label>
                          <Progress value={50} />
                        </div>
                      </td>
                      <td>
                        <div className="gameLabel">
                          <span>1</span>
                          <span>2</span>
                          <span>
                            <img
                              src={
                                require("../../assets/imgs/markAteer.png")
                                  .default
                              }
                            />
                          </span>
                          <span>4</span>
                        </div>
                      </td>
                      <td>90</td>
                      <td>105</td>
                      <td>1</td>
                      <td>147</td>
                    </tr>
                    <tr>
                      <th scope="row">1</th>
                      <td className="gameNameCol">
                        <img
                          src={require("../../assets/imgs/player1.png").default}
                          className="playerImg"
                        />
                        <div className="gameName">
                          <label>Rocky Crush</label>
                          <Progress value={50} />
                        </div>
                      </td>
                      <td>
                        <div className="gameLabel">
                          <span>
                            <img
                              src={
                                require("../../assets/imgs/markAteer.png")
                                  .default
                              }
                            />
                          </span>
                          <span>2</span>
                          <span>3</span>
                          <span>4</span>
                        </div>
                      </td>
                      <td>45</td>
                      <td>99</td>
                      <td>6</td>
                      <td>160</td>
                    </tr>
                    <tr>
                      <th scope="row">1</th>
                      <td className="gameNameCol">
                        <img
                          src={require("../../assets/imgs/player1.png").default}
                          className="playerImg"
                        />
                        <div className="gameName">
                          <label>Gold Flight</label>
                          <Progress value={50} />
                        </div>
                      </td>
                      <td>
                        <div className="gameLabel">
                          <span>1</span>
                          <span>2</span>
                          <span>
                            <img
                              src={
                                require("../../assets/imgs/markAteer.png")
                                  .default
                              }
                            />
                          </span>
                          <span>4</span>
                        </div>
                      </td>
                      <td>90</td>
                      <td>105</td>
                      <td>1</td>
                      <td>147</td>
                    </tr>
                    <tr>
                      <th scope="row">1</th>
                      <td className="gameNameCol">
                        <img
                          src={require("../../assets/imgs/player1.png").default}
                          className="playerImg"
                        />
                        <div className="gameName">
                          <label>Rocky Crush</label>
                          <Progress value={50} />
                        </div>
                      </td>
                      <td>
                        <div className="gameLabel">
                          <span>
                            <img
                              src={
                                require("../../assets/imgs/markAteer.png")
                                  .default
                              }
                            />
                          </span>
                          <span>2</span>
                          <span>3</span>
                          <span>4</span>
                        </div>
                      </td>
                      <td>45</td>
                      <td>99</td>
                      <td>6</td>
                      <td>160</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default MyScores;
