/* eslint-disable jsx-a11y/alt-text */
import { Row, Col, Input, Table, FormGroup, Label } from "reactstrap";
import Prize1 from "../../assets/imgs/prize1.svg";
import Prize2 from "../../assets/imgs/prize2.svg";
import Prize3 from "../../assets/imgs/prize3.svg";
import Crose from "../../assets/imgs/crosemodal.png";
import Advice from "../../assets/imgs/Advice-IMG-1.png";
import "rc-slider/assets/index.css";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { connect } from "react-redux";
import { getLeaderboardAction } from "./../../redux/actions/battleboard";
// import ComingSoon from "../Common/ComingSoon";

const Leaderboards = ({ battleboard, getLeaderboardAction }: any) => {
  const [isPreviousDay, setPreviousDay] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isShowJeaColumn, setShowJeaColumn] = useState(false);
  const [gameMode, setGameMode] = useState(1);
  const [leaderBoard, setLeaderBoardList] = useState<any[]>([]);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleOnChange = (event: any) => {
    console.log(event.target.value);

    if (Number(event.target.value) === 1) {
      setShowJeaColumn(false);
    } else {
      setShowJeaColumn(true);
    }
    setGameMode(event.target.value);
  };
  useEffect(() => {
    setLoader(true);
    const params = {
      gameMode: gameMode,
      gameType: 1,
    };
    getLeaderboardAction(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameMode]);

  useEffect(() => {
    if (battleboard?.data?.leaderboard) {
      setLeaderBoardList([...battleboard?.data?.leaderboard]);
      setLoader(battleboard.loader);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [battleboard]);

  return (
    <>
      {/* <h2 className="CommingSoomLeader">Leaderboards Coming Soon</h2> */}

      <section className="leaderboardsPage innerPageTopSpace py-3">
        <div className="container">
          <h1 className="pageTitle">Battleboards</h1>
          <div className="leaderboardsTopSec">
            <div className="prizePoolTitle">
              Prize pool size for today <strong>500 $TNJ</strong>
            </div>
            <Row className="prizeCardWrapper">
              <Col md="4">
                <div className="prizeCard">
                  <img src={Prize1} />
                  <div className="winnerAmount">
                    <p className="mb-0">For 1st winner</p>
                    <strong>50 $TNJ</strong>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="prizeCard">
                  <img src={Prize2} />
                  <div className="winnerAmount">
                    <p className="mb-0">For 50th winner</p>
                    <strong>30 $TNJ</strong>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="prizeCard">
                  <img src={Prize3} />
                  <div className="winnerAmount">
                    <p className="mb-0">For 100th winner</p>
                    <strong>20 $TNJ</strong>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="petExchangeBtmSec">
          <div className="container">
            <div className="tblTopFilter">
              <Row>
                <Col sm="8" className="pt-3">
                  <div className="tblTopFilterLeftSec">
                    <Input
                      className="form-control"
                      onChange={handleOnChange}
                      id="exampleSelect"
                      name="select"
                      type="select"
                    >
                      <option value={1}>Airship Voyage</option>
                      <option value={2}>Glidonn Voyage</option>
                      <option value={3}>Baby Glidonn Voyage</option>
                    </Input>
                    <FormGroup className="customCheckbox">
                      <Input
                        className="styled-checkbox"
                        name="check"
                        type="checkbox"
                        value="1"
                        id="Previousday"
                        onChange={() => setPreviousDay(!isPreviousDay)}
                      />
                      <Label for="Previousday" id="Custom_Previous">
                        Previous day
                      </Label>
                    </FormGroup>
                  </div>
                </Col>
                <Col sm="4" className="text-right pt-3">
                  <Input
                    className="inputplaceholder"
                    id="exampleEmail"
                    placeholder="Player name"
                    type="text"
                  />
                </Col>
              </Row>
            </div>
            <Row>
              <Col md="12">
                <div className="petListTbl">
                  <Table borderless hover responsive>
                    <thead>
                      <tr>
                        <th>rank</th>
                        <th>player's name</th>
                        <th>Score</th>
                        <th>
                          Earnings
                          {!isPreviousDay ? (
                            <span className="mand">*</span>
                          ) : null}
                        </th>
                        {isShowJeaColumn ? (
                          <>
                            {" "}
                            <th>Jea</th>
                          </>
                        ) : (
                          ""
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {!loader ? (
                        leaderBoard && leaderBoard.length > 0 ? (
                          leaderBoard.map((item: any, index: any) => (
                            <tr>
                              <th scope="row">
                                {item.rank}{" "}
                                {item.rank === 1 ? (
                                  <img
                                    src={
                                      require("../../assets/imgs/tbl-prize1.svg")
                                        .default
                                    }
                                    className="prizeIcon"
                                  />
                                ) : item.rank === 2 ? (
                                  <img
                                    src={
                                      require("../../assets/imgs/tbl-prize2.svg")
                                        .default
                                    }
                                    className="prizeIcon"
                                  />
                                ) : item.rank === 3 ? (
                                  <img
                                    src={
                                      require("../../assets/imgs/tbl-prize3.svg")
                                        .default
                                    }
                                    className="prizeIcon"
                                  />
                                ) : (
                                  ""
                                )}
                              </th>
                              <td>
                                <img
                                  src={
                                    require("../../assets/imgs/player1.png")
                                      .default
                                  }
                                  className="playerImg"
                                />{" "}
                                {item.playerName}
                              </td>
                              <td>{item.score}</td>
                              <td>{item.expectedEarnings.toFixed(2)} $TNJ</td>
                              {isShowJeaColumn ? (
                                <>
                                  <td>
                                    <div
                                      className="modal fade"
                                      id="exampleModalToggle"
                                      aria-hidden="true"
                                      aria-labelledby="exampleModalToggleLabel"
                                    >
                                      <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content position-relative">
                                          <div className="modal-header border-0 py-0">
                                            <h5
                                              className="modal-title"
                                              id="exampleModalToggleLabel"
                                            >
                                              {" "}
                                            </h5>
                                            <button
                                              type="button"
                                              className="btn-close border-0 crosebtn"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              <img src={Crose} width="15px" />
                                            </button>
                                          </div>
                                          <div className="modal-body p-0">
                                            <img
                                              src={Advice}
                                              alt=""
                                              width="100%"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <a
                                      className="btn modalbtn"
                                      data-bs-toggle="modal"
                                      href="#exampleModalToggle"
                                      role="button"
                                    >
                                      Jea
                                    </a>
                                  </td>
                                </>
                              ) : (
                                ""
                              )}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <th colSpan={5} className="text-center">
                              <h6>No data found</h6>
                            </th>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <th colSpan={5} className="text-center">
                            <h3>
                              <i className="fa fa-spinner fa-spin"></i>
                            </h3>
                          </th>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <p className="tblBottomInfo">
                  <span className="mand">*</span>rewards are based on current
                  rank and may change if user is surpassed during the rest of
                  the days competition.
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <div
        className="pet_modal modal fade"
        id="petModal"
        tabIndex={-1}
        aria-labelledby="petModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <h4>PET NAME</h4>
              <div className="pet_img">
                <img
                  src={require("../../assets/imgs/bird.png").default}
                  alt=""
                />
              </div>
              <div className="pet_stats">
                <h5>STATS</h5>
                <div className="stat_wrap">
                  <div className="stat_single">
                    <img
                      className="icn"
                      src={require("../../assets/icons/sun.svg").default}
                      alt=""
                    />
                    <span className="text">99</span>
                  </div>
                  <div className="stat_single">
                    <img
                      className="icn"
                      src={require("../../assets/icons/heart.svg").default}
                      alt=""
                    />
                    <span className="text">99</span>
                  </div>
                  <div className="stat_single">
                    <img
                      className="icn"
                      src={require("../../assets/icons/brain.svg").default}
                      alt=""
                    />
                    <span className="text">99</span>
                  </div>
                  <div className="stat_single">
                    <img
                      className="icn"
                      src={require("../../assets/icons/hand.svg").default}
                      alt=""
                    />
                    <span className="text">99</span>
                  </div>
                  <div className="stat_single">
                    <img
                      className="icn"
                      src={require("../../assets/icons/shocks.svg").default}
                      alt=""
                    />
                    <span className="text">99</span>
                  </div>
                  <div className="stat_single">
                    <img
                      className="icn"
                      src={require("../../assets/icons/cloud.svg").default}
                      alt=""
                    />
                    <span className="text">99</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ battleboard }: { battleboard: any }) => ({
  battleboard,
});
const mapDispatcherToProps = {
  getLeaderboardAction,
};
export default connect(mapStateToProps, mapDispatcherToProps)(Leaderboards);
