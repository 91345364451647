import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

interface IProps {
  projectStatus: any;
  numberOfToken: number;
  onChange: (value: number) => void;
}
const MintTokenVal = ({ projectStatus, numberOfToken, onChange }: IProps) => {
  return (
    <div className="chooseValueOption">
      <div className="chooseRadioOption">
        <FormGroup className={numberOfToken === 1 ? "active" : ""}>
          <Input
            name="radio"
            type="radio"
            value={1}
            onChange={(event) => onChange(Number(event.target.value))}
          />
          <Label>1</Label>
        </FormGroup>
        <FormGroup className={numberOfToken === 2 ? "active" : ""}>
          <Input
            disabled={projectStatus !== "2"}
            className={projectStatus !== "2" ? "disabled" : ""}
            name="radio"
            type="radio"
            value={2}
            onChange={(event) => onChange(Number(event.target.value))}
          />
          <Label className={projectStatus !== "2" ? "disabled" : ""}>2</Label>
        </FormGroup>
        <FormGroup className={numberOfToken === 3 ? "active" : ""}>
          <Input
            disabled={projectStatus !== "2"}
            className={projectStatus !== "2" ? "disabled" : ""}
            name="radio"
            type="radio"
            value={3}
            onChange={(event) => onChange(Number(event.target.value))}
          />
          <Label className={projectStatus !== "2" ? "disabled" : ""}>3</Label>
        </FormGroup>
        <FormGroup className={numberOfToken === 5 ? "active" : ""}>
          <Input
            disabled={projectStatus !== "2"}
            className={projectStatus !== "2" ? "disabled" : ""}
            name="radio"
            type="radio"
            value={5}
            onChange={(event) => onChange(Number(event.target.value))}
          />
          <Label className={projectStatus !== "2" ? "disabled" : ""}>5</Label>
        </FormGroup>
      </div>
      <Input
        disabled={projectStatus !== "2"}
        className={
          projectStatus !== "2" ? "text-center disabled" : "text-center"
        }
        onChange={(event) => onChange(Number(event.target.value))}
        value={numberOfToken}
        type="number"
      />
    </div>
  );
};

export default MintTokenVal;
