import React from "react";
import petBreed from "../../assets/imgs/petBreed1.png";
import evilstarfish from "../../assets/imgs/TNJ 1.svg";
import Birds from "../../assets/imgs/animalfour.png";
import Tree from "../../assets/imgs/treecrop-remove.png";

const PoweringPet = () => {
  return (
    <>
      <div className="bg_yellow sectionfourth">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="dabbalanimal_img">
                <img src={petBreed} width="90%" alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <div>
                  <h5 className="promo__title_decorative mt">
                    Breed and<br></br> Nurture
                  </h5>
                  <p className="my-4 text-left">
                    Your trusty Jea companions use the Tanjea Breeding Pod to
                    create new offspring. Child Jea’s inherit traits from their
                    parents and can be trained to aid their older kin in battles
                    and conquests.
                  </p>
                </div>
                <img src={Birds} width="100%" className="mobilehidden" alt="" />
                <div className="tree_animal mobilehidden">
                  <img src={Tree} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="evilstarfish_img2 mobilehidden">
          <img src={evilstarfish} alt="" />
        </div>
      </div>
    </>
  );
};

export default PoweringPet;
