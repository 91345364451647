const config = {
  cryptoSecret: "m7GMaR3?Efga",
  baseUrl: process.env.REACT_APP_FIREBASE_BASE_URL,
  apiBaseUrl: "https://dev.tanjea.com",
  chainId: 1,
  siteMode: "live", // live or test
  discordLink: "https://discord.gg/tanjea",
};

export default config;
