import React from "react";

const Myland = () => {
  return (
    <section className={`main-content`}>
      <div className="container">
        <div className="row">
          <div className="bgimg">
            <div className="middle">
              <h1>No land found in wallet</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Myland;