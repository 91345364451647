import {
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import BirdImg from "../../assets/imgs/Bird31.png";
import { Filter } from "../Filter";
// import { MyAccountInfo } from "./MyAccountInfo";

const Inventory = () => {
  // Fade Open state
  return (
    <section className="myAccountPage">
      <Row className="my-3">
        <Col lg="4">
          <div className="birdImg">
            <img src={BirdImg} alt="birdImg" className="img-fluid" />
          </div>
        </Col>
        <Col lg="8">
          <div className="petExchangeTopSecData">
            <h1 className="pageTitle text-left mb-3">pet name here</h1>
            <ul>
              <li>
                <label className="text-white">Stamina :</label>
                <strong className="text-white">#lorem</strong>
              </li>
              <li>
                <label className="text-white">Strength :</label>
                <strong className="text-white">#lorem</strong>
              </li>
              <li>
                <label className="text-white">Morale :</label>
                <strong className="text-white">#lorem</strong>
              </li>
              <li>
                <label className="text-white">Intellect :</label>
                <strong className="text-white">#lorem</strong>
              </li>
              <li>
                <label className="text-white">Speed :</label>
                <strong className="text-white">#lorem</strong>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <div className="petExchangeBtmSec">
        <div className="container">
          <Row>
            <Filter />
            <Col lg="8" md="8">
              <div class="form-check custom_checkbox">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                />
                <label class="form-check-label" for="flexCheckChecked">
                  Items
                </label>
              </div>
              <Row>
                <Col md="4">
                  <div className="birdsCard">
                    <div className="birdsImgSec">
                      <img src={BirdImg} alt="birdImg" className="img-fluid" />
                    </div>
                    <div className="birdsName">Bird name here</div>
                    <ul className="p-0 birdsNameul">
                      <li>Stamina : #5</li>
                      <li>Morale : #5</li>
                      <li>Speed : #5</li>
                      <li>Strength : #5</li>
                      <li>Intellect : #5</li>
                    </ul>
                  </div>
                </Col>
                <Col md="4">
                  <div className="birdsCard">
                    <div className="birdsImgSec">
                      <img src={BirdImg} alt="birdImg" className="img-fluid" />
                    </div>
                    <div className="birdsName">Bird name here</div>
                    <ul className="p-0 birdsNameul">
                      <li>Stamina : #5</li>
                      <li>Morale : #5</li>
                      <li>Speed : #5</li>
                      <li>Strength : #5</li>
                      <li>Intellect : #5</li>
                    </ul>
                  </div>
                </Col>
                <Col md="4">
                  <div className="birdsCard">
                    <div className="birdsImgSec">
                      <img src={BirdImg} alt="birdImg" className="img-fluid" />
                    </div>
                    <div className="birdsName">Bird name here</div>
                    <ul className="p-0 birdsNameul">
                      <li>Stamina : #5</li>
                      <li>Morale : #5</li>
                      <li>Speed : #5</li>
                      <li>Strength : #5</li>
                      <li>Intellect : #5</li>
                    </ul>
                  </div>
                </Col>
                <Col md="4">
                  <div className="birdsCard">
                    <div className="birdsImgSec">
                      <img src={BirdImg} alt="birdImg" className="img-fluid" />
                    </div>
                    <div className="birdsName">Bird name here</div>
                    <ul className="p-0 birdsNameul">
                      <li>Stamina : #5</li>
                      <li>Morale : #5</li>
                      <li>Speed : #5</li>
                      <li>Strength : #5</li>
                      <li>Intellect : #5</li>
                    </ul>
                  </div>
                </Col>
                <Col md="4">
                  <div className="birdsCard">
                    <div className="birdsImgSec">
                      <img src={BirdImg} alt="birdImg" className="img-fluid" />
                    </div>
                    <div className="birdsName">Bird name here</div>
                    <ul className="p-0 birdsNameul">
                      <li>Stamina : #5</li>
                      <li>Morale : #5</li>
                      <li>Speed : #5</li>
                      <li>Strength : #5</li>
                      <li>Intellect : #5</li>
                    </ul>
                  </div>
                </Col>
                <Col md="4">
                  <div className="birdsCard">
                    <div className="birdsImgSec">
                      <img src={BirdImg} alt="birdImg" className="img-fluid" />
                    </div>
                    <div className="birdsName">Bird name here</div>
                    <ul className="p-0 birdsNameul">
                      <li>Stamina : #5</li>
                      <li>Morale : #5</li>
                      <li>Speed : #5</li>
                      <li>Strength : #5</li>
                      <li>Intellect : #5</li>
                    </ul>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="birdsPagination my-5">
                    <Pagination>
                      <PaginationItem disabled>
                        <PaginationLink href="#" previous />
                      </PaginationItem>
                      <PaginationItem active>
                        <PaginationLink href="#">1</PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink href="#">2</PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink href="#">3</PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink href="#">4</PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink href="#">5</PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink href="#" next />
                      </PaginationItem>
                    </Pagination>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default Inventory;
