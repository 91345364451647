import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { IStoreInterFace } from "../interfaces/Common";
import rootReducer from "./reducers/index";

const initial: IStoreInterFace = {
  petExplorer: {
    data: null,
    loader: true,
    message: "",
  },
};
export default function configureStore(initialState = initial) {
  return createStore(rootReducer, initialState, applyMiddleware(thunk));
}
