import React from "react";
import FounderOne from "../../assets/imgs/founder1.jpg";
import FounderTwo from "../../assets/imgs/founder2.png";
import FounderThree from "../../assets/imgs/founder3.png";

const Founders = () => {
  return (
    <div className="section bg_yellow">
      <h5 className="promo__title promo__title_decorative">
        The Tanjea Founders
      </h5>
      <div className="main_Founder">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 text-center">
              <div className="Founder_card">
                <div className="Founder_img">
                  <img alt="" src={FounderOne} />
                </div>
                <div className="Founder_content">
                  <p className="Founder_title">
                    <b>@michael-zon</b>
                  </p>
                  <span>Michael Zon: CEO</span>
                  <p>
                    Michael is an early crypto enthusiast, following Ethereum
                    and other projects since 2016. As CEO, Michael oversees the
                    development of the game, managing both the technology and
                    engineering departments.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 text-center">
              <div className="Founder_card">
                <div className="Founder_img">
                  <img alt="" src={FounderTwo} />
                </div>
                <div className="Founder_content">
                  <p className="Founder_title">
                    <b>@ryan-mandel</b>
                  </p>
                  <span>Ryan Mandel: CMO</span>
                  <p>
                    Ryan has been a crypto investor since 2017. He has
                    co-founded and advised on several cryptocurrency companies
                    and projects. He will lead the marketing and advertising
                    initiatives for Tanjea, as well as assist on the overall
                    business strategy and operations.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 text-center">
              <div className="Founder_card">
                <div className="Founder_img">
                  <img alt="" src={FounderThree} />
                </div>
                <div className="Founder_content">
                  <p className="Founder_title">
                    <b>@dylan-shoychet</b>
                  </p>
                  <span>Dylan Shoychet COO</span>
                  <p>
                    Dylan is an entertainment producer and entrepreneur with
                    over 15 years of video and event production experience. He
                    has been a crypto investor since 2018, and has offered his
                    digital marketing strategies and content creation services
                    to various cryptocurrency companies over the 2021 run.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Founders;
