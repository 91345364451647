import React from "react";
import IphonesTanjea from "../../assets/images/2_phones.png";
import egg from "../../assets/imgs/egg.png";
import bg_third from "../../assets/imgs/bg_third.png";

const Capturing = () => {
  return (
    <>
      <div className="bg_parpul sectionspecing">
        <div className="egg_img">
          <img src={egg} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div>
                <div className="my-5 pt-5 race_heading">
                  <h5 className="promo__title_decorative">race to riches</h5>
                  <p className="my-4">
                    Compete in strategic games, form clans,<br></br> and battle
                    other teams for TNJ and<br></br> resources on
                    Tanjea’s land parcels.<br></br>
                    Conquer the most land parcels with your<br></br> clan to be
                    crowned the rulers of Tanjea.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="IphonesTanjea_img">
                <img src={IphonesTanjea} alt="" />
              </div>
            </div>
          </div>
        </div>
        <img src={bg_third} className="bg_third mobilehidden" alt="" />
      </div>
    </>
  );
};

export default Capturing;
