import React from "react";

import finaldone from "../../assets/imgs/turtle.png";
import dabbalanimal1 from "../../assets/imgs/land.png";

const Promo = () => {
  return (
    <>
      <div className="bg_parpul sectionfourth">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="d-flex justify-content-end">
                <div className="my-5 race_heading">
                  <h5 className="promo__title_decorative">Build and Develop</h5>
                  <p className="my-4">
                    Tanjea supports a diverse ecosystem with land, rivers, and
                    waterfalls! By purchasing and developing land in Tanjea,
                    you can host battles and build buildings where users can
                    obtain upgrades. Redeem taxes from purchases to earn
                    TNJ tokens and govern the Tanjea economy.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="IphonesTanjea_img">
                <img src={dabbalanimal1} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="evilstarfish_img1 mobilehidden">
          <img src={finaldone} alt="" />
        </div>
      </div>
    </>
  );
};

export default Promo;
