import React from "react";

const Roadmap = () => {
  return (
    <div className="section bg_parpul">
      <h5 className="promo__title promo__title_decorative">Roadmap</h5>
      <p className="text-center bg_parpul_paragraph mb-0 px-3">
        Once the initial version of the game has been built for the first
        kingdom, Anona, a new kingdom will<br></br> be released each year. Each
        kingdom will introduce new Jeas, land, buildings, and earnable tokens.
        <br></br> Every kingdom will have its own minigame competition mode, a
        passive strategy/battle game, and<br></br> potentially a
        land exploration game mode.
      </p>
      <div className="container">
        <div className="bg_timeline">
          <div className="col-lg-5">
            <div className="timeline_card">
              <h5 className="promo__title_decorative">01 Phase</h5>
              <p>
                Phase one consists of releasing a demo mobile game. 
                This game will include the multiple mini-games
                within the kingdom of Anona. The features include:
              </p>
              <ul>
                <li className="list_style">Global competition mode where all players compete within the
                core minigames for tokens</li>
                <li className="list_style">Ability to capture Jeas</li>
                <li className="list_style"> Breeding Jeas</li>
                <li className="list_style"> Training and leveling up Jeas</li>
                <li className="list_style">Selling and buying Jeas within the in-game marketplace</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-5 next_section">
            <div className="timeline_card">
              <h5 className="promo__title_decorative">02 Phase</h5>
              <p>
                Phase two adds an early version of the land/battle game. 
                Players will be able to battle each other 
                with their Jeas to claim resources in Anona.
              </p>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-lg-5">
              <div className="timeline_card">
                <h5 className="promo__title_decorative">03 Phase</h5>
                <p>
                  Phase three consists of adding the complete land/battle game to
                  Anona. This will enable players to form multiple teams to battle 
                  and compete against other players within Anona on land owners parcels. More
                  details regarding this game mode will be provided in the
                  future.
                </p>
                <p>The additional features relative to phase two are:</p>
                <ul>
                <li className="list_style">
                  Land gameplay for web3 players in which they can choose what
                  buildings to place on their land, the tax rates to use the
                  buildings, and earn tokens based on the amount of traffic to
                  their land relative to other land owners.
                </li>
                
                  <li className="list_style">
                    Mobile players can compete on individual land parcels
                    instead of just the global battle from phase 2.
                  </li>
                  <li className="list_style">
                    Mobile players can use a land owner's buildings to receive
                    additional benefits in battles
                  </li>
                  <li className="list_style">
                    Players can form clans to compete to become the ruler's of
                    Anona
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5 third_section">
              <div className="timeline_card">
                <h5 className="promo__title_decorative">Timelines</h5>
                <ul>
                  <li className="list_style">Q2 2022: Glidonn NFT Giveaway</li>
                  <li className="list_style">
                    Q3 2022: Community beta featuring Glidonn minigame
                    competitions
                  </li>
                  <li className="list_style">
                    Q3 2022: NFT earning, Jea species 2, and minigame
                    competitions for species 2
                  </li>
                  <li className="list_style">
                    Q4 2022: NFT Withdrawal, Jea species 3, and minigame
                    competitions for species 3
                  </li>
                  <li className="list_style">
                    Q4 2022: Team minigame competition mode
                  </li>
                  <li className="list_style">Q4 2022: Open Alpha/MVP</li>
                  <li className="list_style">
                    Q1 2023: Allow in game marketplace items of mobile player to
                    be purchased through site via web3 users
                  </li>
                  <li className="list_style">
                    Q1 2023: Token distribution (potentially earlier, awaiting
                    legal guidance)
                  </li>
                  <li className="list_style">2023: Land battle mode beta</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="free_spece"></div>
          <div className="free_spece"></div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
