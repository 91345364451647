import Profile from "./Common/Profile";
import Inventory from "./Inventory";
import MyScores from "./MyScores";
const MyAccount = (props) => {
  // Fade Open state
  return (
    <section className="myAccountPage py-3 bg_parpul">
      <div className="userInfoSec"></div>
      <div className="container">
        <Profile />
        <ul
          class="nav nav-pills my-5 custom_tabs"
          id="pills-tab"
          role="tablist"
        >
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
            >
              Inventory
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profi"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
            >
              Breed
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-contact-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-contact"
              type="button"
              role="tab"
              aria-controls="pills-contact"
              aria-selected="false"
            >
              My Scores
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-disabled-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-data"
              type="button"
              role="tab"
              aria-controls="pills-disabled"
              aria-selected="false"
            >
              Listed Pets
            </button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
            tabindex="0"
          >
            <Inventory />
          </div>

          <div
            class="tab-pane fade"
            id="pills-contact"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
            tabindex="0"
          >
            <MyScores />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MyAccount;
