import React, { useRef, useState } from "react";
import { Row, Col, Form, Input, Button, FormGroup } from "reactstrap";
import PasswordChecklist from "react-password-checklist";
import Logo from "../../assets/imgs/Header-Logo-Banner.png";
import { useParams } from "react-router-dom";
import { changePassword } from "../../services/CommonServces";
import { toast } from "react-toastify";

const SetPassword = () => {
  const params = useParams();
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const [loader, setLoader] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [passwordAgainFocus, setPasswordAgainFocus] = useState(false);
  const [password, setPassword] = useState<string>("");
  const [passwordAgain, setPasswordAgain] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isValidCPassword, setIsValidCPassword] = useState<boolean>(false);

  const handleSubmit = async () => {
    try {
      if (params.token && isValid && isValidCPassword) {
        setLoader(true);
        const payload = {
          password: password,
          uniqueToken: params.token || "",
        };
        const response = await changePassword(payload);
        setLoader(false);
        const { data } = response;
        console.log(response, "response");
        toast(data?.Data?.Message || "", {
          position: "bottom-right",
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        if (passwordRef?.current) {
          passwordRef?.current?.focus();
        }
      }
    } catch (error) {
      toast("Somthing went wrong, please try again later.", {
        position: "bottom-right",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <section className="set-password">
      <div className="container">
        <Row className="justify-content-center align-items-center set-password-wrapper">
          <Col md="6" lg="5">
            <div className="loginInner">
              <div className="text-center">
                <img src={Logo} alt="Logo" className="loginScreenLogo mb-0" />
              </div>
              <div className="text-center mb-4">
                <h5>
                  <b>Enter your new password below</b>
                </h5>
              </div>
              <Form>
                <FormGroup>
                  <Input
                    innerRef={passwordRef}
                    id=""
                    className="password"
                    name=""
                    placeholder="New Password"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    onFocus={() => setPasswordFocus(true)}
                    onBlur={() => setPasswordFocus(false)}
                  />
                  <PasswordChecklist
                    className="Password_validation px-3 my-3"
                    rules={[
                      "minLength",
                      "specialChar",
                      "number",
                      // "match",
                      "lowercase",
                    ]}
                    minLength={6}
                    value={password}
                    valueAgain={passwordAgain}
                    messages={{
                      minLength: "Password is longer than 6 characters.",
                      specialChar: "The password has special characters.",
                      number: "The password has a number.",
                      // match: "Passwords mismatch.",
                      lowercase: "The password has an alphabets",
                    }}
                    onChange={(isValid) => {
                      setIsValid(isValid);
                      return <p>Valid</p>;
                    }}
                    style={{
                      display: passwordFocus && !isValid ? "block" : "none",
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    id=""
                    className="password"
                    name=""
                    placeholder="Confirm password"
                    type="password"
                    onChange={(e) => setPasswordAgain(e.target.value)}
                    onFocus={() => setPasswordAgainFocus(true)}
                    onBlur={() => setPasswordAgainFocus(false)}
                  />
                  <PasswordChecklist
                    className="Password_validation px-3 my-3"
                    rules={["match"]}
                    minLength={6}
                    value={password}
                    valueAgain={passwordAgain}
                    messages={{
                      match: "Passwords mismatch.",
                    }}
                    onChange={(isValid) => {
                      setIsValidCPassword(isValid);
                    }}
                    style={{
                      display:
                        passwordAgainFocus && !isValidCPassword
                          ? "block"
                          : "none",
                    }}
                  />
                </FormGroup>
                <div className="text-center">
                  <Button
                    disabled={
                      (password === passwordAgain && password !== "") || loader
                        ? false
                        : true
                    }
                    type="button"
                    className="yellowBtn"
                    onClick={handleSubmit}
                    // onClick={() => navigate("/email-confirm")}
                    // disabled={!(formik.isValid && formik.dirty)}
                  >
                    {loader ? <i className="fa fa-spinner fa-spin"></i> : null}{" "}
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default SetPassword;
