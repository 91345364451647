import { FC, useEffect } from "react";
import React from "react";
import { Input, Label, Button, Col, Collapse } from "reactstrap";
import "rc-slider/assets/index.css";
import RangeSlider from "../../components/common/RangeSlider";
import { IFilterProps } from "../../interfaces/Filter";

interface Props {
  filterParams: IFilterProps;
  isBaseStats: string;
  handleOnChangeFilter: (name: string, value: any) => void;
}
const Filter: FC<Props> = ({
  filterParams,
  isBaseStats,
  handleOnChangeFilter,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const updateDimensions = () => {
    const width = window.innerWidth;
    if (width >= 992) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  return (
    <>
      <Col lg="4" md="4" className="text-center pl-0">
        <Button
          className="filterButton"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          Filter
        </Button>
        <Collapse isOpen={isOpen} className="filterWrapper">
          <div className="filterCard">
            <div className="filterTitle">
              <strong className="promo__title_decorative">Filter</strong>
              <Button
                className="filtercloseButton"
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              ></Button>
            </div>

            <div className="testRename">
              {/* <div className="radioGroups">
              <h6>Pets</h6>
              {petTypesOptions.map((item, key) => (
                <div
                  className="customCheckbox yellowInlineCheck"
                  key={`petType-${key}`}
                >
                  <Input
                    className="styled-checkbox"
                    type="checkbox"
                    value={item.value}
                    id={item.value}
                    onChange={(event) => {
                      let petsTypes = filterParams?.petTypes ?? [];
                      if (event.target.checked) {
                        petsTypes.push(event.target.value);
                      } else {
                        petsTypes = petsTypes.filter(
                          (people: string) => people !== event.target.value
                        );
                      }
                      handleOnChangeFilter("petTypes", petsTypes);
                    }}
                  />
                  <Label for={item.value}>{item.label}</Label>
                </div>
              ))}
            </div>
*/}
              <div className="d-flex my-3 justify-content-end">
                {/* <div className="customCheckbox yellowInlineCheck">
                  <Input
                    className="styled-checkbox"
                    type="checkbox"
                    value="1"
                    id="Adult"
                  />
                  <Label for="Adult">Adult</Label>
                </div> */}
                <div className="customCheckbox yellowInlineCheck float-right">
                  <Input
                    onChange={(event) =>
                      handleOnChangeFilter("isAdult", event.target.checked)
                    }
                    className="styled-checkbox"
                    type="checkbox"
                    value="1"
                    id="Child"
                  />
                  <Label for="Child" className="pr-0">
                    Child
                  </Label>
                </div>
              </div>

              <RangeSlider
                name="levelRange"
                handleOnChangeFilter={handleOnChangeFilter}
                label="LV"
                max={100}
                value={filterParams?.levelRange}
              />
              <RangeSlider
                name="staminaRange"
                max={isBaseStats === "base" ? 110 : 110}
                handleOnChangeFilter={handleOnChangeFilter}
                icon={require("../../assets/icons/stamina.png").default}
                value={filterParams?.staminaRange}
              />
              <RangeSlider
                name="strengthRange"
                max={isBaseStats === "base" ? 110 : 110}
                handleOnChangeFilter={handleOnChangeFilter}
                icon={require("../../assets/icons/strength.png").default}
                value={filterParams?.strengthRange}
              />
              <RangeSlider
                name="intellectRange"
                max={isBaseStats === "base" ? 110 : 110}
                handleOnChangeFilter={handleOnChangeFilter}
                icon={require("../../assets/icons/accuracy.png").default}
                value={filterParams?.intellectRange}
              />
              <RangeSlider
                name="moraleRange"
                max={isBaseStats === "base" ? 110 : 110}
                handleOnChangeFilter={handleOnChangeFilter}
                icon={require("../../assets/icons/health.png").default}
                value={filterParams?.moraleRange}
              />
              <RangeSlider
                name="speedRange"
                max={isBaseStats === "base" ? 110 : 110}
                handleOnChangeFilter={handleOnChangeFilter}
                icon={require("../../assets/icons/speed1.png").default}
                value={filterParams?.speedRange}
              />
              <div className="d-flex justify-content-between">
                <div className="customCheckbox">
                  <Input
                    onChange={(event) =>
                      handleOnChangeFilter("breedCount", event.target.checked)
                    }
                    className="styled-checkbox"
                    type="checkbox"
                    value="1"
                    id="Breedable"
                  />
                  <Label for="Breedable">Breedable</Label>
                </div>
                <div className="customCheckbox">
                  <select
                    value={isBaseStats}
                    className="form-control px-1 py-0"
                    onChange={(event) =>
                      handleOnChangeFilter("filter", event.target.value)
                    }
                  >
                    <option value="sum">Total Stats</option>
                    <option value="base">Base Stats</option>
                    <option value="level">Level Stats</option>
                  </select>
                  {/* <Input
                    onChange={(event) =>
                      handleOnChangeFilter("filter", event.target.checked)
                    }
                    className="styled-checkbox"
                    type="checkbox"
                    value="1"
                    id="filter"
                  />
                  <Label for="filter">Level Stats</Label> */}
                </div>
              </div>
              {/* <RangeSlider
              name=""
              handleOnChangeFilter={handleOnChangeFilter}
              icon={require("../../assets/icons/cloud.svg").default}
            /> */}

              {/* <div className="rangeSlider">
              <div className="sliderInputWrap">
                <img
                  className="icn"
                  src={require("../../assets/icons/brain.svg").default}
                  alt=""
                />
                <span>
                  <input type="number" placeholder="min" />
                  <input type="number" placeholder="max" />
                </span>
              </div>
              <Range
                min={1}
                max={200}
                defaultValue={[0, 20]}
                marks={{ 1: 1, 50: 50, 100: 100, 150: 150, 200: 200 }}
              />
            </div>
            <div className="rangeSlider">
              <div className="sliderInputWrap">
                <img
                  className="icn"
                  src={require("../../assets/icons/brain.svg").default}
                  alt=""
                />
                <span>
                  <input type="number" placeholder="min" />
                  <input type="number" placeholder="max" />
                </span>
              </div>
              <Range
                min={1}
                max={200}
                defaultValue={[0, 20]}
                marks={{ 1: 1, 50: 50, 100: 100, 150: 150, 200: 200 }}
              />
            </div>
            <div className="rangeSlider">
              <div className="sliderInputWrap">
                <img
                  className="icn"
                  src={require("../../assets/icons/brain.svg").default}
                  alt=""
                />
                <span>
                  <input type="number" placeholder="min" />
                  <input type="number" placeholder="max" />
                </span>
              </div>
              <Range
                min={1}
                max={200}
                defaultValue={[0, 20]}
                marks={{ 1: 1, 50: 50, 100: 100, 150: 150, 200: 200 }}
              />
            </div>
            <div className="rangeSlider mb-4">
              <div className="sliderInputWrap">
                <img
                  className="icn"
                  src={require("../../assets/icons/brain.svg").default}
                  alt=""
                />
                <span>
                  <input type="number" placeholder="min" />
                  <input type="number" placeholder="max" />
                </span>
              </div>
              <Range
                min={1}
                max={200}
                defaultValue={[0, 20]}
                marks={{ 1: 1, 50: 50, 100: 100, 150: 150, 200: 200 }}
              />
            </div> */}
            </div>
          </div>
        </Collapse>
      </Col>
    </>
  );
};

export default Filter;
