import React from "react";
import stars from "../../../assets/imgs/yellowstar.png";
import profile from "../../../assets/imgs/profile.png";
import star from "../../../assets/imgs/ethereum.png";

const Profile = () => {
  return (
    <div className="container">
      <div className="project_page">
        <h1 className="pageTitle">my account</h1>
        <div className="row justify-content-center">
          <div className="col-lg-3 text-center">
            <div className="d-flex align-items-center justify-content-center h-100">
              <div className="profile_card">
                <p className="profile_title">Deposit to game</p>
                <div className="profile_img">
                  <img alt="" src={stars} width="100%" />
                </div>
                <span>
                  <b>100</b>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 text-center">
            <div className="profile_card">
              <img alt="" src={profile} width="55%" />
              <br></br>
              <p>
                <b>Mark Ateer</b>
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="d-flex align-items-center justify-content-center h-100">
              <div className="profile_card text-center">
                <p className="profile_title">Swap gold and withdraw TNJ</p>
                <div className="profile_img1">
                  <img alt="" src={star} width="65%" height="30px" />
                </div>
                <span>
                  <b>150</b>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
