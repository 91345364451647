import React from "react";
import dabbalanimal from "../../assets/imgs/dabbalanimal.png";
import owlHomepage from "../../assets/imgs/owl.png";
import dabbalanimal_imgp from "../../assets/imgs/bgyellowwhiteshadow.png";

const CollectRare = () => {
  return (
    <>
      <div className="bg_yellow sectionfourth">
        <div className="dabbalanimal_imgp">
          <img src={dabbalanimal_imgp} alt="" />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="dabbalanimal_img">
                <img src={dabbalanimal} width="90%" alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <div className="my-3">
                  <h5 className="promo__title_decorative">
                    Collect<br></br>
                    and Grow
                  </h5>
                  <p className="text-left race_heading">
                    Jeas are the creatures of Tanjea and your companions on this
                    quest. Compete in strategic games to train and level up your
                    Jea to gain an edge on your rivals.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="owlHomepage_img mobilehidden">
          <img src={owlHomepage} alt="" />
        </div>
      </div>
    </>
  );
};

export default CollectRare;
