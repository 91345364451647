import React, { createContext, useState } from "react";
interface ContextState {
  setTheme: (type: IThemeProps) => void;
  theme: IThemeProps;
}

interface IThemeProps {
  _id: string;
  name: string;
}
export const CommonContext = createContext({} as ContextState);

const CommonContextProvider = (props: any) => {
  const [theme, setTheme] = useState<IThemeProps>({ _id: "", name: "Tanjea" });

  const value = {
    theme,
    setTheme,
  };
  return (
    <CommonContext.Provider value={value}>
      {props.children}
    </CommonContext.Provider>
  );
};

export default CommonContextProvider;
