import IphonesTanjea from "../../assets/images/2_phones.png";
import AndriodTanjea from "../../assets/images/Andriod-Tanjea-1.png";
import HomeBanner from "../../components/HomeComponents/HomeBanner";
import { Button, Form, FormGroup, Input } from "reactstrap";

const Waitlist = () => {
  return (
    <main>
      <HomeBanner />
      <div className="container">
        <div className="waitlist-wrapper mt-4">
          <div className="iphone-image">
            <img src={IphonesTanjea} alt="" className="w-100" />
          </div>
          <div className="content-waitlist">
            {" "}
            <div>
              <h4 className="greeting__title_waitlist promo__title_Font text-center">
                Welcome to
              </h4>
              <h3 className="greeting__title greeting__title_decorative_waitlist tanjea_text text-center">
                Tanjea
              </h3>
              <p className="greeting__text_waitlist text-center">
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English.{" "}
              </p>
              <p className="greeting__text_waitlist text-center">
                Many desktop publishing packages and web page editors now use
                Lorem Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.
                Various versions have evolved over the years, sometimes by
                accident, sometimes on purpose (injected humour and the like).
              </p>
            </div>
          </div>
          <div className="android-image">
            <img src={AndriodTanjea} alt="" className="" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row flex-wrap-reverse my-4">
          <div className="col-lg-12">
            <div>
              <h3 className="greeting__title greeting__title_decorative_waitlist tanjea_text text-center">
                Join The Waitlist!
              </h3>
              <div className="w-25 mx-auto text-center">
                <Form>
                  <FormGroup>
                    <Input
                      id="email"
                      className="form-control"
                      name="email"
                      placeholder="Email"
                      type="email"
                    />
                  </FormGroup>
                  <div className="btnGroup">
                    <Button type="submit" className="yellowBtn">
                      Join!
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Waitlist;
