import { handleResponse } from "../../config/common";
import { getLeaderboardApi } from "../../services/Battleboard";

export const getLeaderboardAction = (payload: any) => async (dispatch: any) => {
  dispatch({ type: "ACTION_START" });
  const res = await getLeaderboardApi(payload);
  const response = await handleResponse(res);

  if (response.success) {
    dispatch({
      type: "FETCHED_LEADERBOARD",
      payload: response.data?.leaderboardData ?? [],
    });
  } else {
    dispatch({
      type: "ERROR",
      payload: response.message,
    });
  }
  dispatch({ type: "COMPLETE_ACTION" });
};
