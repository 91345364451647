import { FC } from "react";

import LandChestSalePage from "./pages/LandChestSalePage/LandChestSalePage";

import PetExchange from "./pages/PetExchange/PetExchange";
import Leaderboards from "./pages/Leaderboards/Leaderboards";
import PetExplorer from "./pages/PetExplorer/PetExplorer";
import MyAccount from "./pages/MyAccount/MyAccount";
import Inventory from "./pages/MyAccount/Inventory";
import ListedPets from "./pages/MyAccount/ListedPets";
import MyScores from "./pages/MyAccount/MyScores";
import Breed from "./pages/MyAccount/Breed";
// import Login from "./components/Login/Login";
import Signup from "./pages/Signup/Signup";
import Myland from "./pages/Myland/myland";
// import { Main } from "./pages/Main";
import MetaConnectionContextProvider from "./contexts/MetaConnectionContext";
import CommonContextProvider from "./contexts/CommonContext";
import { ToastContainer } from "react-toastify";
import MainLayout from "./components/Layouts/MainLayout";
import AppRoutes from "./components/AppRoutes";
import LoginLayout from "./components/Layouts/LoginLayout";
import SetPassword from "./pages/ForgotPassword/SetPassword";
import EmailConfirm from "./pages/ForgotPassword/EmailConfirm";
import Home from "./pages/Home/Home";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import Privacypolicy from "./pages/privacy-policy/privacy-policy";
import Waitlist from "./pages/Waitlist";

const App: FC = () => {
  return (
    <div className="App">
      <CommonContextProvider>
        <MetaConnectionContextProvider>
          <AppRoutes
            path="/marketplace/land-chest-sale"
            Component={
              <MainLayout>
                <LandChestSalePage />
              </MainLayout>
            }
          />
          <AppRoutes
            path="/privacy-policy"
            Component={
              <MainLayout>
                <Privacypolicy />
              </MainLayout>
            }
          />
          <AppRoutes
            path="/marketplace/pet-exchange"
            Component={
              <MainLayout>
                <PetExchange />
              </MainLayout>
            }
          />
          <AppRoutes
            path="/battleboards"
            Component={
              <MainLayout>
                <Leaderboards />
              </MainLayout>
            }
          />
          <AppRoutes
            path="/jea-explorer"
            Component={
              <MainLayout>
                <PetExplorer />
              </MainLayout>
            }
          />
          <AppRoutes
            path="/my-account"
            Component={
              <MainLayout>
                <MyAccount />
              </MainLayout>
            }
          />
          <AppRoutes
            path="/my-account/inventory"
            Component={
              <MainLayout>
                <Inventory />
              </MainLayout>
            }
          />
          <AppRoutes
            path="/my-account/listed-pets"
            Component={
              <MainLayout>
                <ListedPets />
              </MainLayout>
            }
          />
          <AppRoutes
            path="/my-account/my-scores"
            Component={
              <MainLayout>
                <MyScores />
              </MainLayout>
            }
          />
          <AppRoutes
            path="/my-account/breed"
            Component={
              <MainLayout>
                <Breed />
              </MainLayout>
            }
          />
          <AppRoutes
            path="/waitlist"
            Component={
              <MainLayout>
                <Waitlist />
              </MainLayout>
            }
          />
          <AppRoutes
            path="/myland"
            Component={
              <MainLayout>
                <Myland />
              </MainLayout>
            }
          />
          <AppRoutes
            path="/signup"
            Component={
              <MainLayout>
                <Signup />
              </MainLayout>
            }
          />
          <AppRoutes
            path="/set-password/:token"
            Component={
              <LoginLayout>
                <SetPassword />
              </LoginLayout>
            }
          />
          <AppRoutes
            path="/email-confirm"
            Component={
              <LoginLayout>
                <EmailConfirm />
              </LoginLayout>
            }
          />

          <AppRoutes
            path="/"
            Component={
              <MainLayout>
                <Home />
              </MainLayout>
            }
          />
          {/* <AppRoutes
            path="/pet-explorer"
            Component={
              <MainLayout>
                <ComingSoon />
              </MainLayout>
            }
          />
          <AppRoutes
            path="/login"
            Component={
              <MainLayout>
                <Login />
              </MainLayout>
            }
          /> */}
          <ToastContainer theme="dark" />
        </MetaConnectionContextProvider>
      </CommonContextProvider>
    </div>
  );
};

export default App;
