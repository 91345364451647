import CryptoJS from "crypto-js";
import { IResponse } from "../interfaces/Common";
import config from "./index";
export function Encrypt(values: any) {
  let encJson = CryptoJS.AES.encrypt(
    JSON.stringify(values),
    config.cryptoSecret
  ).toString();
  let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
  return encData;
}
export function Decrypt(values: any) {
  let decData = CryptoJS.enc.Base64.parse(values).toString(CryptoJS.enc.Utf8);
  let bytes = CryptoJS.AES.decrypt(decData, config.cryptoSecret).toString(
    CryptoJS.enc.Utf8
  );
  return JSON.parse(bytes);
}

export const setCurrentUser = (userData: any) => {
  localStorage.setItem("_pubcoToken", Encrypt(userData.SessionTicket));
  localStorage.setItem("_pubcoUser", Encrypt(JSON.stringify(userData)));
};

export const removeCurrentUser = () => {
  localStorage.removeItem("_pubcoToken");
  localStorage.removeItem("_pubcoUser");
};

export const getCurrentUser = () => {
  return {
    token: localStorage.getItem("_pubcoToken")
      ? Decrypt(localStorage.getItem("_pubcoToken"))
      : null,
    user: localStorage.getItem("_pubcoUser")
      ? JSON.parse(Decrypt(localStorage.getItem("_pubcoUser")))
      : null,
    address: localStorage.getItem("pubco/currentAccount")
      ? localStorage.getItem("pubco/currentAccount")
      : null,
  };
};

export const handleResponse = (responseData: any) => {
  let newResponse: IResponse = {
    success: false,
    data: null,
    message: "",
  };
  try {
    if (responseData.status === 200) {
      const { Data, Message } = responseData.data;

      newResponse = {
        ...newResponse,
        success: true,
        data: Data,
        message: Message ?? "",
      };
    } else {
      newResponse = {
        ...newResponse,
        success: false,
        data: responseData.data,
        message: "",
      };
    }
    return newResponse;
  } catch (error) {
    return newResponse;
  }
};
